import axiosInstance from '../axiosConfig';
import { OrganizationMember } from '../organizations/getOrganizationMembers';

export type GroupRole =
    | 'group_administrator'
    | 'group_creator'
    | 'group_owner'
    | 'group_member';

export interface GroupMember extends Omit<OrganizationMember, 'roles'> {
    companies_count: number;
    roles: GroupRole[];
}

export interface Response {
    count: number;
    next: null;
    previous: null;
    results: GroupMember[];
}

interface Params {
    slug: string;
    offset?: number;
    limit?: number;
    member_name?: string;
    exclude_invitation_pending?: boolean;
}

export const getGroupMembers = async (params: Params) => {
    const { slug, ...restParams } = params;

    const { data } = await axiosInstance.get<Response>(
        `/wt/api/v1/groups/${slug}/members/`,
        {
            params: restParams,
        }
    );

    return data;
};
