import { IconProps } from './types';

const CameraIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = 'black',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 39 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.75 35.5C2.7875 35.5 1.96383 35.1576 1.279 34.4728C0.593 33.7868 0.25 32.9625 0.25 32V11C0.25 10.0375 0.593 9.21383 1.279 8.529C1.96383 7.843 2.7875 7.5 3.75 7.5H9.2625L11.45 5.1375C11.7708 4.7875 12.157 4.51042 12.6085 4.30625C13.0612 4.10208 13.5354 4 14.0312 4H21.25C21.7458 4 22.1618 4.16742 22.4978 4.50225C22.8326 4.83825 23 5.25417 23 5.75V9.25C23 9.74583 23.168 10.1617 23.504 10.4977C23.8388 10.8326 24.2542 11 24.75 11H28.25V14.5C28.25 14.9958 28.418 15.4112 28.754 15.746C29.0888 16.082 29.5042 16.25 30 16.25H33.5C33.9958 16.25 34.4112 16.4174 34.746 16.7522C35.082 17.0882 35.25 17.5042 35.25 18V32C35.25 32.9625 34.9076 33.7868 34.2228 34.4728C33.5368 35.1576 32.7125 35.5 31.75 35.5H3.75ZM17.75 29.375C19.9375 29.375 21.7972 28.6097 23.329 27.079C24.8597 25.5472 25.625 23.6875 25.625 21.5C25.625 19.3125 24.8597 17.4528 23.329 15.921C21.7972 14.3903 19.9375 13.625 17.75 13.625C15.5625 13.625 13.7028 14.3903 12.171 15.921C10.6403 17.4528 9.875 19.3125 9.875 21.5C9.875 23.6875 10.6403 25.5472 12.171 27.079C13.7028 28.6097 15.5625 29.375 17.75 29.375ZM31.75 7.5H30C29.5042 7.5 29.0888 7.33258 28.754 6.99775C28.418 6.66175 28.25 6.24583 28.25 5.75C28.25 5.25417 28.418 4.83825 28.754 4.50225C29.0888 4.16742 29.5042 4 30 4H31.75V2.25C31.75 1.75417 31.918 1.33825 32.254 1.00225C32.5888 0.667417 33.0042 0.5 33.5 0.5C33.9958 0.5 34.4112 0.667417 34.746 1.00225C35.082 1.33825 35.25 1.75417 35.25 2.25V4H37C37.4958 4 37.9112 4.16742 38.246 4.50225C38.582 4.83825 38.75 5.25417 38.75 5.75C38.75 6.24583 38.582 6.66175 38.246 6.99775C37.9112 7.33258 37.4958 7.5 37 7.5H35.25V9.25C35.25 9.74583 35.082 10.1617 34.746 10.4977C34.4112 10.8326 33.9958 11 33.5 11C33.0042 11 32.5888 10.8326 32.254 10.4977C31.918 10.1617 31.75 9.74583 31.75 9.25V7.5Z"
                fill={color}
            />
        </svg>
    );
};

export default CameraIcon;
