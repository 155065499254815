import { IconProps } from './types';

const PlusIcon: React.FC<IconProps> = ({
    width = 17,
    height = '100%',
    color = 'black',
    style,
}) => {
    return (
        <svg
            style={style}
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.3571 9.14286H9.64286V14.8571C9.64286 15.1602 9.52245 15.4509 9.30812 15.6653C9.09379 15.8796 8.80311 16 8.5 16C8.1969 16 7.90621 15.8796 7.69188 15.6653C7.47755 15.4509 7.35714 15.1602 7.35714 14.8571V9.14286H1.64286C1.33975 9.14286 1.04906 9.02245 0.834735 8.80812C0.620408 8.59379 0.5 8.30311 0.5 8C0.5 7.6969 0.620408 7.40621 0.834735 7.19188C1.04906 6.97755 1.33975 6.85714 1.64286 6.85714H7.35714V1.14286C7.35714 0.839753 7.47755 0.549062 7.69188 0.334735C7.90621 0.120407 8.1969 0 8.5 0C8.80311 0 9.09379 0.120407 9.30812 0.334735C9.52245 0.549062 9.64286 0.839753 9.64286 1.14286V6.85714H15.3571C15.6602 6.85714 15.9509 6.97755 16.1653 7.19188C16.3796 7.40621 16.5 7.6969 16.5 8C16.5 8.30311 16.3796 8.59379 16.1653 8.80812C15.9509 9.02245 15.6602 9.14286 15.3571 9.14286Z"
                fill={color}
            />
        </svg>
    );
};

export default PlusIcon;
