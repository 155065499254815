import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import Image, { ImageProps } from 'next/legacy/image';

import { pxToRem } from 'helpers/pxToRem';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';

export const SearchContainer = styled('div')(() => {
    return mq({
        display: 'flex',
        width: ['91.5%', '258px'],
        marginRight: ['0px', '0px', '25px'],
    });
});

export const SearchField = styled('div')(({ theme }) => {
    return mq({
        width: '100%',
        height: ['24px', '36px'],
        border: ['none', '1px solid #D9D9D9'],
        borderRadius: ['0px', '50px'],
        background: theme.colors.primaryColor_4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [mobileStyles]: {
            background: 'none',
        },
    });
});

const Wrapper = styled('div')(() => {
    return mq({
        color: ['#434343', '#92969B'],
        marginLeft: '18px',
        padding: '0',
        cursor: 'pointer',
        marginRight: '11px',
        marginTop: ['4px', '3px'],
    });
});

export const ImgWrapper = styled('div')(() => ({
    width: '43px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
}));

export const Img = (props: ImageProps) => (
    <Wrapper>
        <Image {...props} width={16} height={16} />
    </Wrapper>
);

export const CloseIco = styled(CloseIcon)(({ theme }) => ({
    width: '16px',
    height: '16px',
    color: theme.colors.primaryColor,
    margin: '0',
}));

export const StyledInput = styled('input')(({ theme }) => {
    return mq({
        ...theme.fonts.body_2,

        width: ['85%', '75%'],
        height: '100%',
        border: 'none',
        outline: 'none',
        background: 'transparent',

        '::placeholder': {
            color: theme.colors.primaryText,
        },
    });
});

const arrow = {
    width: '15px',
    height: '19px',
    color: '#666C74',
    marginRight: '9px',
};

export const ArrowUp = styled(KeyboardArrowUpIcon)(() => ({ ...arrow }));

export const ArrowDown = styled(KeyboardArrowDownIcon)(() => ({ ...arrow }));

export const StyledMenu = styled(Menu)(({ theme }) => {
    return mq({
        '& .MuiPaper-root': {
            marginTop: '10px',
            width: ['91.5%', '280px'],
            color: theme.colors.primaryText,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 4,

            '& .MuiList-root': {
                width: '100%',
            },

            '& .MuiMenu-list': {
                padding: '0',
            },
            '& .MuiMenuItem-root': {
                '&:hover': {
                    backgroundColor: '#EBF5FF',
                    color: theme.colors.primaryColor,
                },
            },
        },
    });
});

export const MenuSearchField = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const SearchFieldContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
}));

export const Type = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    textTransform: 'capitalize',
}));

export const Description = styled('p')(({ theme }) => ({
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(12),
    color: theme.colors.secondaryText,
}));
