import { useRouter } from 'next/router';

import useEnvironment from 'hooks/useEnvironment';

import { Wrapper } from './Footer.styled';
import GloProsFooter from './GloPros';
import GlobalProfessionalsFooter from './GlobalProfessionals';

const noPaddingUrls = [
    'talent-referral',
    'sign-up',
    'profile-invitations',
    'plan-expert-meeting',
    'invitation',
];

const Footer = () => {
    const { pathname } = useRouter();
    const { isGloPros, isGlobalProfessionals } = useEnvironment();

    const withoutPadding = noPaddingUrls.some((url) => pathname.includes(url));

    const FooterBody = () => {
        if (isGloPros) return <GloProsFooter />;

        if (isGlobalProfessionals) return <GlobalProfessionalsFooter />;

        return null;
    };

    return (
        <Wrapper $withoutPadding={withoutPadding}>
            <FooterBody />
        </Wrapper>
    );
};

export default Footer;
