import { CSSProperties } from 'react';

import { IconProps } from './types';

interface Props extends IconProps {
    hideExpandMore?: boolean;
    rotate?: boolean;
}

const ExpandMoreIcon: React.FC<Props> = ({
    width,
    height,
    color = 'black',
    isCursorPointer,
    style,
    hideExpandMore = false,
    rotate,
}) => {
    const expandMoreStyle = {
        display: hideExpandMore ? 'none' : 'auto',
        pointerEvents: hideExpandMore ? 'none' : 'auto',
    };

    const cursorStyle = { cursor: isCursorPointer ? 'pointer' : 'auto' };
    const rotateStyles = { transform: `rotate(${rotate ? '180deg' : '0deg'})` };

    const customStyle = {
        ...style,
        ...cursorStyle,
        ...expandMoreStyle,
        ...rotateStyles,
    } as CSSProperties | undefined;

    return (
        <svg
            style={customStyle}
            width={width}
            height={height}
            viewBox="0 0 21 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5 1L10.5 10L1.5 1"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ExpandMoreIcon;
