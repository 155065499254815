import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

import { transientOptions } from 'helpers/transientOptions';

export const FooterBlock = styled('div')(() => ({
    width: '100%',
    minHeight: '469px',
    background: 'linear-gradient(180deg, #012B5B 0%, #03172F 100%)',
    display: 'flex',
    flexDirection: 'column',
    padding: '19px 16px',
}));

interface StyledAccordionProps {
    $mainColor?: string;
    $borderNone?: boolean;
    $fontStyles?: { [key: string]: string };
}

export const StyledAccordion = styled(
    Accordion,
    transientOptions
)<StyledAccordionProps>(({ $mainColor, $borderNone, $fontStyles }) => ({
    ...$fontStyles,
    background: 'transparent',
    color: $mainColor ? $mainColor : 'white',
    boxShadow: 'none',
    borderBottom: $borderNone ? 'none' : '1px solid rgba(206, 214, 225, 0.5)',
    borderRadius: '0px!important',

    '&:before': {
        display: 'none',
    },
}));

interface IconProps {
    $mainColor?: string;
}

export const StyledExpandMoreIcon = styled(
    ExpandMoreIcon,
    transientOptions
)<IconProps>(({ $mainColor, theme }) => ({
    color: $mainColor ? $mainColor : theme.colors.white,
}));

export const StyledTypographyTitle = styled('p')(() => ({}));
