import {
    DialogActions,
    DialogContent,
    DialogTitle,
    styled,
} from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)(
    ({ theme: { colors, fonts } }) => ({
        ...fonts.h5_headline,
        color: colors.primaryText,
        padding: '35px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    })
);

export const StyledDialogActions = styled(DialogActions)({
    padding: '35px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',

    button: {
        flex: 1,
    },
});

export const StyledDialogContent = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '35px',
});
