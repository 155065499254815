import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';

export const HeaderBlock = styled(
    'div',
    transientOptions
)<{ $withBorder: boolean }>(({ theme, $withBorder }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 32px',
    borderBottom: $withBorder
        ? `1px solid ${theme.colors.dividerColor}`
        : 'none',
    alignItems: 'center',
    flexWrap: 'wrap',
}));

export const Logo = styled('img')(() =>
    mq({
        width: ['10px', '125px', '153px'],
        height: ['27px', '27px', '33px'],
    })
);

export const ImgWrapper = styled('div')(() =>
    mq({
        marginRight: ['0px', '12px', '30px'],
        cursor: 'pointer',
    })
);

export const ContentL = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const ContentR = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
}));

export const Buttons = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const LinkButton = styled('button')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        margin: ['0 8px', '0 8px', '0 12px'],
        cursor: 'pointer',
        border: 'none',
        background: 'none',
    })
);

export const TalkToExpertsWrapper = styled('div')(() =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: ['0px', '0px', '20px'],
        marginRight: ['0px', '0px', '22px'],
    })
);

export const ActionButton = styled('button')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        background: theme.colors.secondaryColor_basic,
        color: theme.colors.primaryText,
        width: ['91px', '91px', '109px'],
        height: '36px',
        borderRadius: '50px',
        border: 'none',
        cursor: 'pointer',
    })
);

export const LoginButton = styled('button')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        padding: ['6px 10px', '6px 10px', '6px 17px'],
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        color: theme.colors.primaryText,
        minWidth: '80.5px',
    })
);

export const DisabledMenuItem = styled(MenuItem)(({ theme }) => ({
    cursor: 'not-allowed',
    color: theme.colors.dividerColor,

    '&:hover': {
        color: `${theme.colors.dividerColor}!important`,
    },
}));
