import { useMediaQuery } from '@mui/material';

import { Breakpoints } from 'types/breakpoints';

export const useDisplayType = () => {
    const isMobile = useMediaQuery(
        `@media (max-width: ${Breakpoints.MOBILE}px)`
    );
    const isTablet = useMediaQuery(
        `@media (min-width: ${Breakpoints.MOBILE}px) and (max-width: ${Breakpoints.TABLET}px)`
    );
    const isDesktop = useMediaQuery(
        `@media (min-width: ${Breakpoints.DESKTOP}px)`
    );

    return { isMobile, isTablet, isDesktop };
};
