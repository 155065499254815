import { useFlags } from '@unleash/proxy-client-react';

// if feature flag enabled we should show feature
const useFeatureFlags = (...flagsToFind: string[]) => {
    const unleashedFlags = useFlags();

    return flagsToFind.map((flag) => {
        const searchedFlag = unleashedFlags.filter(
            (unleashedFlag) => unleashedFlag.name === flag
        );

        // if there is no such flag return false, which means to disabled feature
        if (searchedFlag.length === 0) {
            return false;
        }

        return searchedFlag[0]?.enabled;
    });
};

export default useFeatureFlags;
