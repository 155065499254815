import { styled } from '@mui/material';

export const Title = styled('h2')(({ theme: { colors, fonts } }) => ({
    ...fonts.body_5,
    color: colors.primaryText,
    textAlign: 'center',
}));

export const Description = styled('h5')(({ theme: { colors, fonts } }) => ({
    ...fonts.body_4,
    color: colors.primaryText,
    textAlign: 'center',
}));

export const Buttons = styled('div')({
    display: 'flex',
    gap: '15px',
    marginTop: '15px',

    button: {
        flex: 1,
    },
});
