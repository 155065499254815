import { styled } from '@mui/material';

import { mobileStyles } from 'types/displayTypeStyles';

export const Content = styled('div')({
    width: '100%',
    display: 'flex',
    gap: '90px',
    padding: '110px 90px 170px',
    justifyContent: 'center',

    [mobileStyles]: {
        flexDirection: 'column',
        padding: '19px 16px 150px',
        gap: '0px',
    },
});

export const Background = styled('div')(({ theme: { colors } }) => ({
    width: '100%',
    background: colors.footerGlobalProfessionals,
}));
