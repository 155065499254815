import axiosInstance from 'api/axiosConfig';

export const addMember = async (
    slug: string,
    data: { recruiter_id: string; role: string }
) => {
    const response = await axiosInstance.post(
        `/wt/api/v1/company/${slug}/add-member/`,
        data
    );

    return response;
};
