import axiosInstance from './axiosConfig';

type Choice = {
    value: number;
    display_name: string;
};

type Select = {
    type: string;
    required: boolean;
    read_only: boolean;
    label: string;
    choices: Choice[];
};

interface Response {
    actions: {
        PUT: {
            company_size: Select;
            industry_active: Select;
            type_of_company: Select;
            service_or_product: Select;
        };
    };
}

const getCompanyOptions = (slug: string) =>
    axiosInstance.options<Response>(`/wt/api/v1/company/${slug}/`);

export default getCompanyOptions;
