import { Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import useOnClickOutside from 'hooks/useOnClickOutside';

import StyledPopper from 'shared/formElements/StyledPopper';

import { InputsStateProps } from '../ExtendedSearchMenu';
import { InputTitle, StyledInput } from './AutocompleteInput.styled';

interface AutocompleteInputProps {
    title: string;
    placeholder: string;
    inputValue?: string;
    inputFormKey: 'location' | 'job_title';
    isOnFocus: boolean;
    setOnFocus: Dispatch<SetStateAction<boolean>>;
    isDisabled?: boolean;
    searchHandler?: () => void;
    children: ReactNode;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
    title,
    placeholder,
    inputFormKey,
    children,
    isOnFocus,
    setOnFocus,
    searchHandler,
}) => {
    const jobTitleAnchor = useRef<HTMLInputElement>(null);
    const { setValue, register, watch } = useFormContext<InputsStateProps>();
    const { name } = register(inputFormKey);

    useOnClickOutside(
        jobTitleAnchor,
        () => {
            setOnFocus(false);
        },
        'liPopper'
    );

    return (
        <>
            <InputTitle>{title}</InputTitle>

            <StyledInput
                name={name}
                placeholder={placeholder}
                onFocus={() => setOnFocus(true)}
                onChange={(e) => setValue(inputFormKey, e.target.value)}
                value={watch(inputFormKey)}
                ref={jobTitleAnchor}
                autoComplete="off"
                onKeyDown={(e) => {
                    if (
                        e.key === 'Enter' &&
                        watch(inputFormKey) &&
                        searchHandler
                    ) {
                        searchHandler();
                    }
                }}
            />

            <StyledPopper isOpen={isOnFocus} anchorEl={jobTitleAnchor}>
                {children}
            </StyledPopper>
        </>
    );
};

export default AutocompleteInput;
