import { ReactNode, RefObject } from 'react';

import { CustomPopper } from './StyledPopper.styled';

interface StyledPopperProps {
    isOpen: boolean;
    anchorEl: RefObject<HTMLDivElement | HTMLInputElement | null>;
    children: ReactNode;
}

const StyledPopper: React.FC<StyledPopperProps> = ({
    isOpen,
    anchorEl,
    children,
}) => {
    return (
        <CustomPopper
            open={isOpen}
            anchorEl={anchorEl.current}
            placement="bottom-start">
            {children}
        </CustomPopper>
    );
};

export default StyledPopper;
