import { KeyedMutator } from 'swr';
import { create } from 'zustand';

export const mutationKeys = {
    settingsUserRolesInTeam: (clientId: number) => `/team-roles-${clientId}/`,
    settingsAvailableTeamsForUser: (clientId: number) =>
        `/available-teams-${clientId}/`,
    settingsUserTeams: (clientId: number) => `/user-teams-${clientId}/`,
    settingsTeamsKey: '/organization-teams/',
    teamsKey: '/organization-teams/',
    teamsTabCounterKey: '/teams-counter/',
    membersCounterKey: '/members-counter/',
    teamsLastActivity: '/teams-activity/',
    teamsCompanyMembersKey: '/company-members/',

    // profile/settings/organization/members/
    settingsOrganizationsMembersKey: '/organization-members/',
    vacancy: (id: number) => `vacancy-${id}`,
    vacancyPage: (id: number) => `profile-vacancy-${id}`,
};

interface MutationsState {
    mutations: Record<string, KeyedMutator<any>>;
    setMutations: (key: string, mutation: KeyedMutator<any>) => void;
}

export const useMutationsStore = create<MutationsState>((set) => ({
    mutations: {},

    setMutations: (key, mutateFn) =>
        set(({ mutations }) => ({
            mutations: { ...mutations, [key]: mutateFn },
        })),
}));
