import { useDisplayType } from 'hooks/useDisplayType';
import { useFormValues } from 'hooks/useFormValues';
import { useUserData } from 'state/userData';
import { theme } from 'theme/theme';
import { SearchInput } from 'types/componentTypes';

import SearchIcon from 'components/SVGIcons/SearchIcon';

import { ButtonWrapper } from '../ExtendedSearchMenu.styled';
import JobTitleInput from '../JobTitleInput';
import LocationInput from '../LocationInput';
import {
    LeftInputContainer,
    RightInputContainer,
    RightInputWrapper,
} from './SearchInputs.styled';

interface Props {
    searchHandler: () => void;
    input: SearchInput;
}

const SearchInputs: React.FC<Props> = ({ searchHandler, input }) => {
    const { isMobile } = useDisplayType();
    const themeColors = theme.colors;
    const formValues = useFormValues();
    const isLoggedIn = useUserData((store) => store.isLoggedIn);

    const isFormHasValues = Boolean(
        formValues.location || formValues.job_title
    );

    switch (input.argName) {
        case 'job_title': {
            return (
                <LeftInputContainer key={input.id} $isLoggedIn={isLoggedIn}>
                    <JobTitleInput
                        title={input.name}
                        inputFormKey={input.argName}
                        placeholder={input.placeholder}
                        searchHandler={searchHandler}
                    />
                </LeftInputContainer>
            );
        }
        case 'location': {
            return (
                <RightInputWrapper key={input.id}>
                    <RightInputContainer>
                        <LocationInput
                            title={input.name}
                            inputFormKey={input.argName}
                            placeholder={input.placeholder}
                            searchHandler={searchHandler}
                        />
                    </RightInputContainer>

                    {isMobile && (
                        <ButtonWrapper
                            $isPointer={isFormHasValues}
                            onClick={searchHandler}>
                            <SearchIcon
                                width={20}
                                height={20}
                                color={themeColors.white}
                            />
                        </ButtonWrapper>
                    )}
                </RightInputWrapper>
            );
        }
        default: {
            return null;
        }
    }
};

export default SearchInputs;
