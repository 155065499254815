import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { getSeparatedString } from '@helpers/getSeparatedString';
import getCompanyOptions from 'api/getCompanyOptions';
import { getGroupMembers } from 'api/groups/getGroupMembers';
import { useUserData } from 'state/userData';

interface Item {
    value: number;
    display_name: string;
}

interface StateTypes {
    companyIndustry: Item[];
    companySize: Item[];
    companyType: Item[];
    groupMembers?: { value: string; display_name: string }[];
}

export const useCompanyProfileOptions = (groupSlug?: string) => {
    const {
        user: { companySlug },
    } = useUserData();

    const [options, setOptions] = useState<StateTypes>({
        companyIndustry: [],
        companySize: [],
        companyType: [],
        groupMembers: [],
    });

    const companyOptionsFetcher = async () => {
        if (!companySlug) return;

        const { data } = await getCompanyOptions(companySlug);
        const res = groupSlug
            ? await getGroupMembers({
                  slug: groupSlug,
                  limit: 100,
                  offset: 0,
                  exclude_invitation_pending: true,
              })
            : null;

        const elements = data.actions.PUT;

        const groupMembers = res?.results
            .map((member) => ({
                value: String(member.recruiter_id),
                display_name: getSeparatedString(
                    [member.first_name, member.last_name],
                    ' '
                ),
            }))
            .filter((member) => member.display_name);

        const modifiedOptions = {
            companyIndustry: elements.industry_active.choices,
            companySize: elements.company_size.choices,
            companyType: elements.type_of_company.choices,
            groupMembers,
        };

        setOptions(modifiedOptions);

        return modifiedOptions;
    };

    const { data: companyOptions } = useSWR(
        `/options/${companySlug}`,
        companyOptionsFetcher,
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        companyOptions && setOptions(companyOptions);
    }, [companyOptions]);

    return options;
};
