import { useRouter } from 'next/router';

import useApiUrl from '@hooks/useApiUrl';
import { useGetUtmSessionTags } from '@hooks/useUTMtags';

const useGetLoginUrl = (url: string, title: string) => {
    const { query } = useRouter();
    const apiUrl = useApiUrl();

    const { gtm_debug } = query;
    const utmTags = useGetUtmSessionTags();

    const urlConstructor = new URL(`${apiUrl}${url}`);

    if (title === 'Login') {
        let params: Record<string, string> = {};

        if (utmTags) params = { ...params, ...utmTags };
        if (gtm_debug) params['gtm_debug'] = gtm_debug as string;

        urlConstructor.search = new URLSearchParams(params).toString();
    }

    return urlConstructor.href;
};

export default useGetLoginUrl;
