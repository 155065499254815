import { styled } from '@mui/material';

import { transientOptions } from 'helpers/transientOptions';

export const Wrapper = styled(
    'div',
    transientOptions
)<{ $withoutPadding: boolean }>(({ $withoutPadding }) => ({
    width: '100%',
    marginTop: 'auto',
    paddingTop: $withoutPadding ? '0px' : '110px',
}));
