import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')<{ $isOpened: boolean }>(
    ({ theme: { colors, fonts }, $isOpened }) => ({
        ...fonts.body_1,
        color: colors.primaryColor1,
        display: 'flex',
        padding: '6px 24px',
        gap: '16px',
        alignItems: 'center',
        borderRadius: '30px',
        border: `1px solid ${colors.secondaryColor_4}`,
        marginLeft: '20px',
        flexShrink: 0,
        cursor: 'pointer',
        background: $isOpened ? colors.primaryColor_4 : 'transparent',

        '&:hover': {
            background: colors.primaryColor_4,
            border: `1px solid ${colors.primaryColor}`,
            boxShadow: '0px 0px 5px 0px rgba(17, 24, 33, 0.30)',
        },
    })
);

export const CompanyImageWrapper = styled('div')({
    width: '28px',
    height: '28px',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
});

export const ProfileCircleImageWrapper = styled('div')({
    width: '28px',
    height: '28px',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '200px 16.4px 200px 200px',
});

export const ModalProfileImageWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '200px 15px 200px 200px',
    width: '144px',
    height: '138px',
    background: theme.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    filter: 'drop-shadow(0px 0px 5px rgba(17, 24, 33, 0.3))',
    overflow: 'hidden',
}));

export const CustomPopover = styled(Popover)(() => ({
    marginTop: '10px',

    '& .MuiPaper-root': {
        background: 'transparent',
        boxShadow: ' 0px 0px 5px rgba(17, 24, 33, 0.3)',
        borderRadius: '60px 10px 10px 10px',
    },
}));

export const PopoverBody = styled('div')(() => ({
    width: '235px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
    alignItems: 'center',
}));

export const UserName = styled('h3')(({ theme }) => ({
    ...theme.fonts.body_1,
    marginTop: '10px',
    textAlign: 'center',

    '&:empty': {
        display: 'none',
    },
}));

export const UserTypeLabel = styled('div')(({ theme }) => ({
    ...theme.fonts.caption,
    position: 'absolute',
    right: '0',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    background: theme.colors.primaryColor,
    borderRadius: '0px 12px 0px 50px',
    color: theme.colors.white,
    textAlign: 'center',
    minWidth: '64%',
    zIndex: 1,

    '&:empty': {
        display: 'none',
    },
}));

export const StyledButton = styled('button')(({ theme, disabled }) => ({
    ...theme.fonts.body_3,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 24px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: 'none',
    background: 'transparent',
    color: disabled ? theme.colors.dividerColor : theme.colors.secondaryText,
    '&:hover': {
        background: theme.colors.primaryColor_4,
    },
}));

export const LogOutButton = styled('button')(({ theme }) => ({
    ...theme.fonts.body_3,
    padding: '5px 38px',
    color: theme.colors.primaryText,
    background: 'transparent',
    border: `1px solid ${theme.colors.secondaryColor_2}`,
    borderRadius: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    marginTop: '18px',
    cursor: 'pointer',
    '&:hover': {
        background: theme.colors.primaryColor_4,
    },
}));

export const BecomePartner = styled('p')(({ theme }) => ({
    ...theme.fonts.caption,
    color: theme.colors.primaryColor,
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '10px',
}));

export const ItemsWrapper = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',

    '&:empty': {
        display: 'none',
    },
});
