import { useDisplayType } from 'hooks/useDisplayType';
import { theme } from 'theme/theme';

import Accordion from 'components/Accordion';

import Constructor from '../Constructor';
import { ConstructorProps } from '../Constructor/Constructor';
import { Content, FooterBox, Title } from './VerticalSection.styled';

const { h6_headline } = theme.fonts;

export interface VerticalSectionType {
    type: 'verticalSection';
    title: string;
    content: ConstructorProps[];
    isVisible?: boolean;
}

const VerticalSection: React.FC<VerticalSectionType> = ({
    title,
    content,
    isVisible = true,
}) => {
    const { isMobile } = useDisplayType();

    if (!isVisible) return null;

    const ContentBody = content.map((el, idx) => (
        <Constructor key={idx} {...el} />
    ));

    if (isMobile)
        return (
            <Accordion
                title={title}
                styles={{ ...h6_headline }}
                detailsStyles={{ display: 'flex', flexDirection: 'column' }}>
                {ContentBody}
            </Accordion>
        );

    return (
        <FooterBox>
            <Title>{title}</Title>

            <Content>{ContentBody}</Content>
        </FooterBox>
    );
};

export default VerticalSection;
