import { styled } from '@mui/material';

import { transientOptions } from 'helpers/transientOptions';

export const SelectedTabWrapper = styled(
    'div',
    transientOptions
)<{
    $secondaryStyle: boolean;
}>(({ theme, $secondaryStyle }) => ({
    ...theme.fonts.caption,
    padding: '9px 10px',
    color: $secondaryStyle ? theme.colors.primaryColor : theme.colors.white,
    background: $secondaryStyle
        ? theme.colors.primaryColor_4
        : theme.colors.primaryColor,
    borderRadius: '50px',
    gap: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'auto',
}));

export const IconWrapper = styled('div')(() => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
