import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormContext } from 'react-hook-form';

import { ValidationElement } from './ValidationRule.styled';

interface Props {
    isValidRule: boolean;
    ruleText: string;
    isFocusLost: boolean;
}

const ValidationIconsStyle = {
    fontSize: '16px',
};

const ValidationRule: React.FC<Props> = ({
    isValidRule,
    ruleText,
    isFocusLost,
}) => {
    const {
        formState: { isSubmitted },
    } = useFormContext();

    const isErrored =
        (isSubmitted && !isValidRule) || (isFocusLost && !isValidRule);

    return (
        <ValidationElement $withError={isErrored}>
            {isValidRule ? (
                <CheckIcon style={ValidationIconsStyle} />
            ) : (
                <ClearIcon style={ValidationIconsStyle} />
            )}
            {ruleText}
        </ValidationElement>
    );
};

export default ValidationRule;
