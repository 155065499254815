import useApiUrl from 'hooks/useApiUrl';
import {
    ComponentExternalLink,
    ComponentInternalLink,
} from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';

import CustomLink from 'components/CustomLink';

import {
    Arrow,
    ContentWrapper,
    LinkWrapper,
    Title,
} from './DecorativeLink.styled';

const DecorativeLink: React.FC<
    ComponentInternalLink | ComponentExternalLink
> = ({ type, value, className }) => {
    const wagtailAPI = useApiUrl();
    const isInternal = type === ComponentType.internalLink;

    const url = value.reference.url;
    const href = isInternal ? url : `${wagtailAPI}${url}`;

    const Content = () => {
        return (
            <ContentWrapper>
                <Title>{value.title}</Title>
                <Arrow src="/img/arrow_header.svg" alt="arrow" />
            </ContentWrapper>
        );
    };

    return (
        <LinkWrapper
            className={`decorativeLink ${className}`}
            $alignment={value.alignment}>
            {isInternal ? (
                <CustomLink href={href}>
                    <Content />
                </CustomLink>
            ) : (
                <a href={href}>
                    <Content />
                </a>
            )}
        </LinkWrapper>
    );
};

export default DecorativeLink;
