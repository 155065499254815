import useClientRuntimeConfig from 'state/useClientRuntimeConfig';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

const useApiUrl = () => {
    const { config: clientConfig } = useClientRuntimeConfig();
    const { NEXT_PUBLIC_SWAGGER_API: CLIENT_API } = clientConfig;
    const { NEXT_PUBLIC_SWAGGER_API: CASHED_API } = getCachedRuntimeConfig();
    return (
        process.env.NEXT_PUBLIC_SWAGGER_API || CLIENT_API || CASHED_API || ''
    );
};

export default useApiUrl;
