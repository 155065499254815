import Constructor from '../Components/Constructor';
import { Background, Content } from './GlobalProfessionalsFooter.styled';
import { useGlobalProfessionalsFooterSchema } from './schema';

const GlobalProfessionalsFooter = () => {
    const schema = useGlobalProfessionalsFooterSchema();

    return (
        <Background>
            <Content>
                {schema.map((el, idx) => (
                    <Constructor key={idx} {...el} />
                ))}
            </Content>
        </Background>
    );
};

export default GlobalProfessionalsFooter;
