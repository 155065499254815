export const PROFILE_CHANGE_SUCCESS =
    'Success! Your changes are waiting for expert`s approval';
export const SUCCESSFUL_INVITE = 'Successfully invited';
export const SUCCESSFUL_UPLOAD = 'Successfully uploaded';
export const SUCCESSFUL_UPDATED = 'Successfully updated';
export const SUCCESSFUL_APPLIED = 'Successfully applied';
export const SUCCESSFUL_ARCHIVED = 'Successfully archived';
export const SUCCESSFUL_RESTORED = 'Successfully restored';
export const SUCCESSFUL_DELETED = 'Successfully deleted';
export const SUCCESSFUL_MERGED = 'Successfully merged';
export const SUCCESSFUL_CREATED = 'Successfully applied';
export const NO_USER_ID = 'No user id';
export const NO_COMPANY_ID = 'No company id';
export const FAIL_MESSAGE = 'Oops something went wrong';
export const SERVER_ERROR = 'Server error';
export const DELETE_ACCOUNT_SUCCESS =
    'Your account has been successfully deleted';
export const FAIL_INVITE = 'Failed to invite';
export const NO_FILE_LOADED = 'No file loaded';
