import axiosInstance from '../axiosConfig';

// get organization teams - /profile/settings/organization/teams/

export interface Creator {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    birth_date: string;
    gender: string;
    citizenship: number;
    phone_number: string;
    photo: {
        title: string;
        height: number;
        width: number;
        url: string;
    };
    recruiter_slug: string;
}

export interface Team {
    id: number;
    slug: string;
    title: string;
    is_personal: boolean;
    created_at: string;
    created_by: Creator;
    owner: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        birth_date: string;
        gender: string;
        citizenship: number;
        location: null;
        phone_number: string;
        photo: {
            title: string;
            height: number;
            width: number;
            url: string;
        };
        job: string | null;
        recruiter_slug: string;
    };
    company: {
        id: number;
        slug: string;
        title: string;
        company_size: number;
        industry_active: number;
        type_of_company: number;
        country: string;
        city: string;
        about: string;
    };
    company_group: {
        slug: string;
        title: string;
    };
    vacancies_count: number;
    is_archived: boolean;
}

export interface Response {
    count: number;
    results: Team[];
}

export interface Params {
    is_personal?: boolean;
    limit?: number;
    offset?: number;
    company?: number;
    user?: number;
    exclude_user?: number;
    title?: string;
}

const getWorkspaces = ({ limit = 12, ...params }: Params) => {
    return axiosInstance.get<Response>(`/wt/api/v1/workspace/`, {
        params: {
            limit,
            ...params,
        },
    });
};

export default getWorkspaces;
