import useSWR from 'swr';

import getOrganizationMembers from 'api/organizations/getOrganizationMembers';
import { mutationKeys } from 'state/useMutateHandlers';
import { useUserData } from 'state/userData';

const { membersCounterKey } = mutationKeys;

export const membersCounterFetcher = async (companySlug: string | null) => {
    if (!companySlug) return;

    const { data } = await getOrganizationMembers(companySlug, {
        limit: 1,
        offset: 0,
    });
    return data.count;
};

export const useGetMembersCounter = () => {
    const { user } = useUserData();
    const { companySlug } = user;

    const { data: membersCounter, mutate } = useSWR(
        companySlug ? membersCounterKey : undefined,
        () => membersCounterFetcher(companySlug),
        {
            revalidateOnFocus: false,
        }
    );

    return { membersCounter, mutate };
};
