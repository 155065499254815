import Image from 'next/legacy/image';

import CustomLink from 'components/CustomLink';

import { ISO, Wrapper } from './IsoContent.styled';

export interface ISOContent {
    type: 'isoContent';
    content: {
        title: string;
        href: string;
        icon: string;
    }[];
}

const IsoContent: React.FC<ISOContent> = ({ content }) => {
    return (
        <Wrapper>
            {content.map((el, idx) => (
                <CustomLink href={el.href} key={idx}>
                    <ISO>
                        {el.title}

                        <Image
                            alt="Iso Content"
                            src={el.icon}
                            width={60}
                            height={60}
                        />
                    </ISO>
                </CustomLink>
            ))}
        </Wrapper>
    );
};

export default IsoContent;
