export const hasEnoughCharacters = (password: string, length: number) => {
    return password.trim().length >= length;
};

export const hasLetters = (password: string) => {
    return /^(?=.*[a-z])(?=.*[A-Z])/.test(password);
};

export const hasNumbers = (password: string) => {
    return /\d/.test(password);
};

export const hasSpaces = (password: string) => {
    return !password.startsWith(' ') && !password.endsWith(' ');
};
