import { zodResolver } from '@hookform/resolvers/zod';
import Dialog from '@mui/material/Dialog';
import Blob from 'cross-blob';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import axiosServerError from '@helpers/axiosServerError';
import { useCompanyProfileOptions } from '@hooks/useCompanyProfileOptions';
import useConfirmModal from '@hooks/useConfirmModal';
import { addMember } from 'api/organizations/addMember';
import createNewOrganization from 'api/organizations/createNewOrganization';

import ColoredButton from 'components/Buttons/ColoredButton';
import CloseIcon from 'components/SVGIcons/CloseIcon';
import CustomInput from 'shared/formElements/CustomInput';
import CustomMultipleChipsSelect from 'shared/formElements/CustomMultipleChipsSelect';
import CustomPhoneInput from 'shared/formElements/CustomPhoneInput';
import CustomSelectInput from 'shared/formElements/CustomSelectInput';

import AddCompanyLogo from './AddCompanyLogo';
import {
    StyledDialogActions,
    StyledDialogContent,
    StyledDialogTitle,
} from './CreateNewOrganization.styled';

interface Props {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    mutateList: () => void;
    groupSlug?: string;
}

const schema = z.object({
    photo: z.instanceof(Blob).optional(),
    name: z.string().min(1, 'Required'),
    website: z
        .string()
        .min(1, 'Required')
        .regex(
            /^https?:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-z]{2,})(\/\S*)?$/i,
            'Please provide a valid url'
        ),
    phoneNumber: z.string().min(1, 'Required'),
    email: z.string().min(1, 'Required').email(),
    industry: z.string().min(1, 'Required').or(z.number().min(1, 'Required')),
    size: z.string().or(z.number()),
    typeOfOrganization: z.string().or(z.number()),
    administrators: z.array(z.string()).optional(),
});

type FormType = z.infer<typeof schema>;

const CreateNewOrganization: React.FC<Props> = ({
    isOpen,
    setOpen,
    mutateList,
    groupSlug,
}) => {
    const { companyIndustry, companySize, companyType, groupMembers } =
        useCompanyProfileOptions(groupSlug);

    const methods = useForm<FormType>({
        defaultValues: {
            photo: undefined,
            name: '',
            website: '',
            phoneNumber: '',
            email: '',
            industry: '',
            size: '',
            typeOfOrganization: '',
            administrators: [],
        },
        resolver: zodResolver(schema),
    });

    const { handleSubmit, getValues, formState } = methods;

    const closeDialog = () => {
        setOpen(false);
        methods.reset();
    };

    const { ConfirmModal, handleClose } = useConfirmModal({
        closeModal: closeDialog,
        isDirty: formState.isDirty,
    });

    const createHandler = async () => {
        const {
            name,
            photo,
            website,
            phoneNumber,
            email,
            industry,
            size,
            typeOfOrganization,
            administrators,
        } = getValues();

        try {
            const { data } = await createNewOrganization({
                title: name,
                photo: photo,
                website: website,
                phone_number: phoneNumber,
                email: email,
                industry_active: industry,
                company_size: size,
                type_of_company: typeOfOrganization,
            });

            if (administrators) {
                await Promise.all(
                    administrators.map((adminId: string) =>
                        addMember(data.slug, {
                            recruiter_id: adminId,
                            role: 'company_administrator',
                        })
                    )
                );
            }

            mutateList();
            closeDialog();
        } catch (error) {
            axiosServerError({ error });
        }
    };

    return (
        <FormProvider {...methods}>
            <ConfirmModal />

            <Dialog
                open={isOpen}
                onClose={handleClose}
                scroll="paper"
                PaperProps={{ sx: { width: '620px', borderRadius: '20px' } }}>
                <StyledDialogTitle>
                    Add Organisation
                    <CloseIcon
                        width="15px"
                        isCursorPointer
                        onClick={handleClose}
                    />
                </StyledDialogTitle>

                <StyledDialogContent dividers>
                    <AddCompanyLogo formKey="photo" />

                    <CustomInput
                        inputFormKey="name"
                        placeholder="Name"
                        borderRadius={4}
                    />

                    <CustomMultipleChipsSelect
                        formKey="administrators"
                        placeholder="Assign administrators"
                        itemsArray={groupMembers || []}
                        isChipsInput
                    />

                    <CustomInput
                        inputFormKey="website"
                        placeholder="Website"
                        borderRadius={4}
                    />

                    <CustomPhoneInput formKey="phoneNumber" />

                    <CustomInput
                        inputFormKey="email"
                        placeholder="Organisation email"
                        borderRadius={4}
                    />

                    <CustomSelectInput
                        formKey="industry"
                        placeholder="Organisation industry"
                        itemsToSelect={companyIndustry}
                    />

                    <CustomSelectInput
                        formKey="size"
                        placeholder="Organisation size"
                        itemsToSelect={companySize}
                    />

                    <CustomSelectInput
                        formKey="typeOfOrganization"
                        placeholder="Type of organisation"
                        itemsToSelect={companyType}
                    />
                </StyledDialogContent>

                <StyledDialogActions>
                    <ColoredButton
                        handler={handleClose}
                        title="Cancel"
                        color="transparent"
                        disabled={formState.isSubmitting}
                    />

                    <ColoredButton
                        title="Add Organisation"
                        handler={handleSubmit(createHandler)}
                        isLoading={formState.isSubmitting}
                    />
                </StyledDialogActions>
            </Dialog>
        </FormProvider>
    );
};

export default CreateNewOrganization;
