import useStoredHeader from '@hooks/useStoredHeader';
import useEnvironment from 'hooks/useEnvironment';

import GloProsHeader from './GloProps/GloProsHeader';
import GlobalProfessionalsHeader from './GlobalProfessionals';

const Header: React.FC = () => {
    const { isGloPros, isGlobalProfessionals } = useEnvironment();

    const { headerProps } = useStoredHeader();

    if (isGloPros) return <GloProsHeader {...headerProps} />;

    if (isGlobalProfessionals) return <GlobalProfessionalsHeader />;

    return null;
};

export default Header;
