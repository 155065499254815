import {
    Box,
    Checkbox,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import compact from 'lodash/compact';
import Image from 'next/legacy/image';
import React, { CSSProperties, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useApiUrl from '@hooks/useApiUrl';

import Loader from 'components/Loader';
import CustomExpandIcon from 'components/SVGIcons/CustomExpandIcon';
import SelectedTab from 'shared/formAdditionalComponents/SelectedTab';

import {
    BottomButtons,
    Placeholder,
    RWrapper,
    SelectWrapper,
    StyledBox,
    StyledButton,
    StyledFormControl,
} from './CustomMultipleChipsSelect.styled';

interface ChipsImageProps {
    url?: string;
}

const ChipsImage: React.FC<ChipsImageProps> = ({ url }) => {
    const apiUrl = useApiUrl();

    if (!url) return null;

    return (
        <Box
            sx={{
                width: '24px',
                height: '24px',
                borderRadius: '50px',
                position: 'relative',
                overflow: 'hidden',
            }}>
            <Image src={`${apiUrl}/${url}`} layout="fill" />
        </Box>
    );
};

const menuProps = { MenuListProps: { sx: { paddingBottom: '0px' } } };

export interface SelectItem {
    value: string;
    display_name: string;
    withDivider?: boolean;
    img?: string;
}

interface Props {
    label?: string;
    formKey: string;
    itemsArray?: SelectItem[];
    isLoading?: boolean;
    borderRadius?: string;
    placeholder?: string;
    isChipsInput?: boolean;
    sx?: CSSProperties;
}

const CustomMultipleChipsSelect: React.FC<Props> = ({
    label,
    formKey,
    itemsArray,
    isLoading,
    borderRadius = '4px',
    placeholder,
    isChipsInput = false,
    sx,
}) => {
    const {
        watch,
        setValue,
        formState: { errors, isSubmitting },
        trigger,
        control,
    } = useFormContext();
    const formValues = watch(formKey) as string[];

    const [values, setValues] = useState<string[]>(formValues);

    const [isOpen, setIsOpen] = useState(false);

    const errorText = errors[formKey]?.message;

    const selectCheckboxHandler = (value: string) => () => {
        const modifiedValues = new Set(values);

        if (values.includes(value)) {
            modifiedValues.delete(value);
        } else {
            modifiedValues.add(value);
        }

        setValues([...modifiedValues]);
    };

    const handleClearAll = () => setValues([]);

    const handleDeleteFromChips = (value: string) => () => {
        const modifiedValues = values.filter((el) => el !== value);
        setValues(modifiedValues);
    };

    const closeHandler = () => {
        setValues(formValues);
        setIsOpen(false);
        trigger(formKey);
    };

    const openHandler = () => {
        setValues(formValues);
        setIsOpen(true);
    };

    const applyHandler = () => {
        setValue(formKey, values);
        setIsOpen(false);
        trigger(formKey);
    };

    const renderValue = (selected: string[]) => {
        if (selected.length === 0 && placeholder)
            return <Placeholder>{placeholder}</Placeholder>;

        if (isChipsInput) {
            return (
                <StyledBox>
                    {selected.map((value) => {
                        const selectedEl = itemsArray?.find(
                            (el) => el.value === value
                        );

                        const title = selectedEl?.display_name;

                        if (!title) return null;

                        return (
                            <SelectedTab
                                title={title}
                                value={value}
                                key={value}
                                inputFormKey={formKey}
                                onDelete={handleDeleteFromChips(value)}
                                sx={{ height: '32px' }}>
                                <ChipsImage url={selectedEl?.img} />
                            </SelectedTab>
                        );
                    })}
                </StyledBox>
            );
        }

        const elements = compact(selected).map((el) => {
            return itemsArray?.find(({ value }) => value === el)?.display_name;
        });

        return elements.join(', ');
    };

    return (
        <Controller
            name={formKey}
            control={control}
            render={({ field: { onChange } }) => (
                <StyledFormControl>
                    {label && <InputLabel shrink>{label}</InputLabel>}

                    <Select
                        multiple
                        displayEmpty
                        open={isOpen}
                        onOpen={openHandler}
                        onClose={closeHandler}
                        variant="outlined"
                        MenuProps={menuProps}
                        value={values}
                        disabled={isSubmitting}
                        sx={{ borderRadius, ...sx }}
                        input={<OutlinedInput notched label={label} />}
                        IconComponent={(props) => (
                            <CustomExpandIcon
                                style={{
                                    width: '10px',
                                    marginRight: '10px',
                                }}
                                {...props}
                            />
                        )}
                        renderValue={renderValue}>
                        <SelectWrapper>
                            {isLoading && <Loader height="200px" size={30} />}

                            {itemsArray?.map(
                                ({ value, display_name, withDivider }, idx) => (
                                    <MenuItem
                                        divider={withDivider}
                                        key={idx}
                                        value={value}
                                        onClick={selectCheckboxHandler(value)}>
                                        <Checkbox
                                            checked={values.includes(value)}
                                        />

                                        <ListItemText
                                            primary={display_name}
                                            primaryTypographyProps={{
                                                whiteSpace: 'normal',
                                            }}
                                        />
                                    </MenuItem>
                                )
                            )}
                        </SelectWrapper>

                        <BottomButtons>
                            <StyledButton onClick={handleClearAll}>
                                Clear all
                            </StyledButton>

                            <RWrapper>
                                <StyledButton onClick={closeHandler}>
                                    Cancel
                                </StyledButton>

                                <StyledButton
                                    onClick={() => onChange(applyHandler)}
                                    disabled={!values.length}>
                                    Apply
                                </StyledButton>
                            </RWrapper>
                        </BottomButtons>
                    </Select>

                    {errorText && (
                        <FormHelperText error>
                            {errorText as React.ReactNode}
                        </FormHelperText>
                    )}
                </StyledFormControl>
            )}
        />
    );
};
export default CustomMultipleChipsSelect;
