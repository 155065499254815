import { Box, MenuItem, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import useGetLoginUrl from '@helpers/useGetLoginUrl';
import useApiUrl from 'hooks/useApiUrl';
import { useDisplayType } from 'hooks/useDisplayType';
import { theme } from 'theme/theme';
import {
    ComponentHeader,
    ComponentInternalLink,
    ExtendedSearchMenuItem,
    HeaderProfileButton,
    ItemMenu,
    MenuItems,
    ProfileMenuItems,
    SearchMenuItems,
    featuresLinks,
} from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';

import CustomLink from 'components/CustomLink';
import DecorativeLink from 'components/DecorativeLink';
import DropDown from 'components/DropDown';
import LogoIcon from 'components/SVGIcons/LogoIcon';

import ExtendedSearchMenu from '../ExtendedSearchMenu';
import ProfileMenu from '../ProfileMenu';
import Search from '../Search';
import {
    ActionButton,
    ContentL,
    ContentR,
    DisabledMenuItem,
    HeaderBlock,
    ImgWrapper,
    LinkButton,
    Logo,
    TalkToExpertsWrapper,
} from './HeaderLG.styled';

const { primaryColor, secondaryText, secondaryColor_4 } = theme.colors;

interface LayoutComponents {
    menu_items: React.FC<MenuItems>;
    menu_item: React.FC<ItemMenu>;
    internal_link: React.FC<ComponentInternalLink>;
    search_menu_items: React.FC<SearchMenuItems>;
    action: React.FC<HeaderProfileButton>;
    extended_search_menu_item: React.FC<ExtendedSearchMenuItem>;
    profile_menu_items: React.FC<ProfileMenuItems>;
}

const MenuItemLink: React.FC<ItemMenu> = ({ url, style, title }) => {
    const Button = <LinkButton>{title}</LinkButton>;
    const isLogin = title === 'Login';
    const loginUrl = useGetLoginUrl(url, title);

    switch (style) {
        case 'internal':
            return <CustomLink href={url}>{Button}</CustomLink>;
        case 'external':
            return <a href={isLogin ? loginUrl : url}>{Button}</a>;
        default:
            return null;
    }
};

const HeaderLG: React.FC<ComponentHeader> = ({
    navigationBar,
    logo,
    withBorder = true,
}) => {
    const apiUrl = useApiUrl();
    const { isTablet } = useDisplayType();
    const router = useRouter();
    const isSignUpPage = router.asPath.includes('sign-up');

    const layout: LayoutComponents = {
        menu_items: ({ items, title }) => {
            return (
                <DropDown title={title}>
                    {items.map((item, id) => {
                        if (item.type === ComponentType.groupTitle) {
                            return (
                                <Typography
                                    key={id}
                                    variant="body_4"
                                    component="p"
                                    color={secondaryText}
                                    px={2}
                                    pt={2}>
                                    {item.title}
                                </Typography>
                            );
                        }

                        if (item.type === ComponentType.divider) {
                            return (
                                <Box
                                    key={`divider-${id}`}
                                    sx={{
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: secondaryColor_4,
                                    }}
                                />
                            );
                        }

                        const { style, url, title } = item;

                        const disabledLink = featuresLinks.includes(title);
                        const MenuButton = <MenuItem>{title}</MenuItem>;

                        if (disabledLink) {
                            return (
                                <DisabledMenuItem key={id}>
                                    {title}
                                </DisabledMenuItem>
                            );
                        }

                        switch (style) {
                            case 'internal': {
                                return (
                                    <CustomLink key={id} href={url}>
                                        {MenuButton}
                                    </CustomLink>
                                );
                            }
                            case 'external': {
                                return (
                                    <a key={id} href={url}>
                                        {MenuButton}
                                    </a>
                                );
                            }
                            default:
                                return null;
                        }
                    })}
                </DropDown>
            );
        },

        menu_item: MenuItemLink,

        internal_link: (component) => (
            <TalkToExpertsWrapper>
                <DecorativeLink {...component} />
            </TalkToExpertsWrapper>
        ),

        search_menu_items: (component) => {
            return <Search {...component} />;
        },

        action: ({ style, action: { url }, title }) => {
            const Button = <ActionButton>{title}</ActionButton>;

            switch (style) {
                case 'internal': {
                    return <CustomLink href={url}>{Button}</CustomLink>;
                }
                case 'external': {
                    return <a href={url}>{Button}</a>;
                }
                default:
                    return null;
            }
        },

        extended_search_menu_item: (component) => {
            return <ExtendedSearchMenu {...component} />;
        },

        profile_menu_items: (component) => {
            return <ProfileMenu {...component} />;
        },
    };

    return (
        <HeaderBlock $withBorder={withBorder}>
            <ContentL>
                <CustomLink href="/">
                    <ImgWrapper>
                        {logo ? (
                            <Logo src={`${apiUrl}${logo}`} alt="Logo" />
                        ) : (
                            <LogoIcon
                                width={isTablet ? 125 : 153}
                                height={isTablet ? 27 : 33}
                                color={primaryColor}
                            />
                        )}
                    </ImgWrapper>
                </CustomLink>

                {!isSignUpPage &&
                    navigationBar?.leftSide.map((component) => {
                        const Component = layout[component.type] as React.FC;

                        if (!Component) return null;

                        return <Component key={component.id} {...component} />;
                    })}
            </ContentL>

            <ContentR>
                {!isSignUpPage &&
                    navigationBar?.rightSide.map((component) => {
                        const Component = layout[component.type] as React.FC;

                        if (!Component) return null;

                        return <Component key={component.id} {...component} />;
                    })}
            </ContentR>
        </HeaderBlock>
    );
};

export default HeaderLG;
