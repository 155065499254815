import { styled } from '@mui/material';

import { transientOptions } from 'helpers/transientOptions';

interface LinkWrapperProps {
    $alignment: string;
}

export const LinkWrapper = styled(
    'div',
    transientOptions
)<LinkWrapperProps>(({ $alignment, className }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:
        $alignment === 'right'
            ? 'flex-end'
            : $alignment === 'center'
              ? 'center'
              : 'flex-start',

    marginTop: className?.includes('uniqueComponent') ? '20px' : '',
}));

export const Title = styled('p')(({ theme }) => ({
    ...theme.fonts.link_button,
    color: theme.colors.primaryColor,
    cursor: 'pointer',
    marginRight: '9px',
}));

export const ContentWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Arrow = styled('img')(() => ({
    width: '6',
    height: '10',
}));
