import { styled } from '@mui/material';

export const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const ISO = styled('div')(({ theme: { fonts } }) => ({
    ...fonts.body_2,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
}));
