import dayjs from 'dayjs';

import Constructor from '../Components/Constructor';
import { AllRightsText, Background, Content } from './GloProsFooter.styled';
import { useGloProsFooterSchema } from './schema';

const GloProsFooter = () => {
    const schema = useGloProsFooterSchema();
    const currentYear = dayjs().year();

    return (
        <Background>
            <Content>
                {schema.map((el, idx) => (
                    <Constructor key={idx} {...el} />
                ))}
            </Content>

            <AllRightsText>
                © {currentYear} GloPros. All rights reserved
            </AllRightsText>
        </Background>
    );
};

export default GloProsFooter;
