import { theme } from 'theme/theme';

import CustomLink from 'components/CustomLink';

const { body_1 } = theme.fonts;

export interface PageLinkType {
    type: 'link';
    title: string;
    href: string;
}

const PageLink: React.FC<PageLinkType> = ({ title, href }) => {
    return (
        <CustomLink style={{ ...body_1, color: 'white' }} href={href}>
            {title}
        </CustomLink>
    );
};

export default PageLink;
