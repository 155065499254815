import { styled } from '@mui/material';

import { mobileStyles } from 'types/displayTypeStyles';

export const Wrapper = styled('div')(({ theme }) => ({
    ...theme.fonts.body_1,
    width: '22%',
    height: '100%',
    background: theme.colors.secondaryColor_8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `1px solid ${theme.colors.dividerColor}`,
    borderRadius: '50px',

    [mobileStyles]: {
        width: '100%',
        borderRight: 'none',
        borderRadius: '0px',
        padding: '0px 20px',
        borderBottom: `1px solid ${theme.colors.dividerColor}`,
        height: '87px',
    },
}));

export const MenuSearchField = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
}));

export const SearchFieldContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
}));

export const Type = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    textTransform: 'capitalize',
}));

export const Description = styled('p')(({ theme }) => ({
    ...theme.fonts.caption,
    textTransform: 'capitalize',
    color: theme.colors.secondaryText,
}));

export const StyledButton = styled('button')(({ theme }) => ({
    ...theme.fonts.body_1,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',

    [mobileStyles]: {
        width: '100%',
        justifyContent: 'space-between',
    },
}));

export const PopoverBody = styled('div')(({ theme }) => ({
    marginTop: '5px',
    boxShadow: '0px 4px 20px rgba(17, 24, 33, 0.25)',
    borderRadius: '30px',
    border: `1px solid ${theme.colors.primaryColor}`,
    background: theme.colors.white,
    zIndex: '999',
    overflow: 'hidden',

    [mobileStyles]: {
        width: '90vw',
        justifyContent: 'space-between',
        maxWidth: '700px',
    },
}));
