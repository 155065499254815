import { IconProps } from './types';

const CrossInCircleIcon: React.FC<IconProps> = ({
    width,
    height,
    color = 'black',
}) => {
    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-testid="cross-in-circle-icon"
            viewBox="1.44 1.44 17.13 17.13">
            <path
                d="M12.8125 7.1875L7.1875 12.8125M7.1875 7.1875L12.8125 12.8125"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 17.8125C14.3147 17.8125 17.8125 14.3147 17.8125 10C17.8125 5.68528 14.3147 2.1875 10 2.1875C5.68528 2.1875 2.1875 5.68528 2.1875 10C2.1875 14.3147 5.68528 17.8125 10 17.8125Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CrossInCircleIcon;
