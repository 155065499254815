import useSWR from 'swr';

import getWorkspaces from 'api/workspaces/getWorkspaces';
import { mutationKeys } from 'state/useMutateHandlers';

const { teamsTabCounterKey } = mutationKeys;

export const teamsFetcher = async () => {
    const { data } = await getWorkspaces({
        limit: 1,
        offset: 0,
        is_personal: false,
    });
    return data.count;
};

export const useGetTeamsCounter = () => {
    const { data: teamsCounter, mutate } = useSWR(
        teamsTabCounterKey,
        teamsFetcher,
        {
            revalidateOnFocus: false,
        }
    );

    return { teamsCounter, mutate };
};
