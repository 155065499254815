import { theme } from 'theme/theme';
import {
    ComponentHeader,
    ExtendedSearchMenuItem,
    ProfileMenuItems,
} from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';

import CustomLink from 'components/CustomLink';
import LogoIcon from 'components/SVGIcons/LogoIcon';

import ExtendedSearchMenu from '../ExtendedSearchMenu';
import ProfileMenu from '../ProfileMenu';
import CustomDrawer from './Drawer';
import {
    Content,
    ContentL,
    ContentR,
    HeaderBlock,
    Hr,
    LogoWrapper,
} from './HeaderSM.styled';

const primaryColor = theme.colors.primaryColor;

const HeaderSM: React.FC<ComponentHeader> = ({ navigationBar }) => {
    const extendedSearchComponent = navigationBar?.rightSide.find(
        ({ type }) => type === ComponentType.extendedSearchMenuItem
    ) as ExtendedSearchMenuItem;

    const profileMenuComponent = navigationBar?.rightSide.find(
        ({ type }) => type === ComponentType.profileMenuItems
    ) as ProfileMenuItems;

    return (
        <>
            <HeaderBlock>
                <Content>
                    <ContentL>
                        {navigationBar && <CustomDrawer {...navigationBar} />}

                        <CustomLink href="/">
                            <LogoWrapper>
                                <LogoIcon
                                    width={90}
                                    height={20}
                                    color={primaryColor}
                                />
                            </LogoWrapper>
                        </CustomLink>
                    </ContentL>

                    <ContentR>
                        {extendedSearchComponent && (
                            <ExtendedSearchMenu {...extendedSearchComponent} />
                        )}

                        {profileMenuComponent && (
                            <ProfileMenu {...profileMenuComponent} />
                        )}
                    </ContentR>
                </Content>
            </HeaderBlock>

            <Hr />
        </>
    );
};

export default HeaderSM;
