import axiosInstance from '../axiosConfig';

type CompanyGroup = {
    slug: string;
    title: string;
};

type Photo = {
    id: number;
    title: string;
    width: number;
    height: number;
    created_at: string;
    path: string;
};

type LedgerInfo = {
    bank: string;
    bic_number: string;
    iban_or_bank: string;
};

type LegalEntity = {
    entity_type: string;
    eori_number: string;
    btw_vat_number: string;
};

type AddressSection = {
    id: string;
    type: string;
    value: {
        city: string;
        type?: string[];
        region?: string;
        street?: string;
        country: string;
        day_in_office?: string | null;
        number_of_street?: string;
        address?: string;
        postal_code?: string;
        additional_address_details?: string;
    };
};

type InvoicingDetails = {
    tax_rate: string;
    ledger_info: LedgerInfo;
    company_name: string;
    legal_entity: LegalEntity;
    company_email: string;
    billing_address: {
        main: AddressSection;
        custom: AddressSection[];
        additional: AddressSection[];
    };
    company_phone_number: string;
};

export type Organization = {
    id: number;
    slug: string;
    title: string;
    photo: Photo;
    company_size: number;
    industry_active: number;
    service_or_product: string;
    invoicing_mode: string;
    phone_number: string;
    type_of_company: number;
    birth_date: string;
    first_name: string;
    last_name: string;
    website: string;
    email: string;
    last_modified: string;
    company_groups: CompanyGroup;
    gender: string;
    invoicing_details: InvoicingDetails;
    job: string;
    broker: null;
};

export interface Response {
    count: number;
    next: null;
    previous: null;
    results: Organization[];
}

export interface Params {
    offset?: number;
    limit?: number;
    title?: string;
    user?: number;
    // will be rewrite all other filters
    exclude_user?: number;
}

const getClientOrganizations = (params?: Params) =>
    axiosInstance.get<Response>(`/wt/api/v1/company/`, {
        params,
    });

export default getClientOrganizations;
