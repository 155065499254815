import { RefObject } from 'react';

import useEventListener from './useEventListener';

type Handler = (event: MouseEvent) => void;

function useOnClickOutside<T extends HTMLElement | null = HTMLElement>(
    ref: RefObject<T>,
    handler: Handler,
    excludeNodes: string,
    mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
    useEventListener(mouseEvent, (event) => {
        const el = ref?.current;

        const exclude = [...(event.target as Element).classList].includes(
            excludeNodes
        );

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node) || exclude) {
            return;
        }

        handler(event);
    });
}

export default useOnClickOutside;
