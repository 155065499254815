import { Dispatch, SetStateAction } from 'react';

import { ComponentColor, ComponentStyle, ComponentType } from './constantTypes';

export interface ComponentCharBlock extends AdditionalParentProps {
    type: ComponentType.charBlock;
    value: string;
    id: string;
}

export interface ImageValue {
    title: string;
    height: number;
    width: number;
    url: string;
}

export type ButtonColors = 'primary' | 'secondary' | 'blank' | '';

export interface ComponentActionsList {
    id: string;
    type: ComponentType.actionsList;
    value: {
        actions: {
            title: string;
            accentColor: ButtonColors;
            action: {
                slug: string;
                url: string;
                title: string;
            };
        }[];
    };
}

export interface ComponentImage extends AdditionalParentProps {
    type: ComponentType.image;
    value: ImageValue;
    id: string;
}

export interface ComponentImages extends AdditionalParentProps {
    type: ComponentType.images;
    value: ImageValue[];
    id: string;
}

export interface ComponentRichText extends AdditionalParentProps {
    type?: ComponentType.richText;
    value: string;
    id?: string;
    className?: string;
}

export interface DecorativeLinkValue extends AdditionalParentProps {
    value: {
        title: string;
        alignment: string;
        target: string;
        reference: {
            slug?: string;
            url: string;
            title?: string;
        };
    };
    id: string;
    className?: string;
}

export interface ComponentInternalLink extends DecorativeLinkValue {
    type: ComponentType.internalLink;
}

export interface ComponentExternalLink extends DecorativeLinkValue {
    type: ComponentType.externalLink;
}

export type ParentStyle =
    | ComponentStyle.grayscale
    | ComponentStyle.blank
    | ComponentStyle.bigChessBlock
    | ComponentStyle.smallChessBlock
    | ComponentStyle.offsetChessBlock
    | ComponentStyle.mediumChessBlock
    | ComponentStyle.customIllustrationChessBlock;

interface AdditionalParentProps {
    parentStyle?: ParentStyle;
}

export interface StyledSection {
    type: ComponentType.styledSection;
    value: {
        title: string;
        accentColor: ComponentColor.primary | ComponentColor.secondary;
        content: (
            | ComponentImage
            | ComponentWrappedImage
            | ComponentImages
            | ComponentAction
            | ComponentCards
            | ComponentExternalLink
            | ComponentInternalLink
            | ComponentCharBlock
            | ComponentRichText
        )[];
        style: ParentStyle;
    };

    id: string;
}

export interface ComponentHeroValues {
    style:
        | ComponentStyle.legal
        | ComponentStyle.regular
        | ComponentStyle.home
        | ComponentStyle.marketingHome
        | ComponentStyle.bigIllustration;
    title: string;
    description: string;
    images: ImageValue[];
    references: (
        | ComponentAction
        | ComponentExternalLink
        | ComponentInternalLink
    )[];
}

export interface ComponentHero extends ComponentHeroValues {
    type: ComponentType.heroBlock;
    id: string;
    value: ComponentHeroValues;
}

export interface Partner {
    type: ComponentType.customPartner | ComponentType.partner;
    id: string;
    value: {
        firstName: string;
        lastName: string;
        jobTitle: string;
        photo: {
            id: number;
            title: string;
            width: number;
            height: number;
            created_at: string;
            path: string;
        };
    };
}

export interface PartnerList {
    type: ComponentType.partnerList;
    id: string;
    value: {
        partners: [Partner];
    };
}

export interface Review {
    type: ComponentType.customReviewBlock | ComponentType.reviewBlock;
    id: string;
    value: {
        fullName: string;
        jobTitle: string;
        label: string;
        companyName: string;
        description: string;
        photo: string;
    };
}

export interface ReviewSlider {
    type: ComponentType.reviewSlider;
    id: string;
    value: {
        reviews: [Review];
    };
}

export interface ComponentAction extends AdditionalParentProps {
    type: ComponentType.action;
    value: {
        title: string;
        accentColor: ButtonColors;
        action: {
            slug: string;
            url: string;
            title: string;
        };
    };
    id: string;
}

export interface WrappedImageValue {
    title: string;
    image: {
        title: string;
        height: number;
        width: number;
        url: string;
        alignment: string;
        accentColor: string;
    };
    content: (ComponentAction | ComponentRichText)[];
}

export interface CroppedPhotoBlock extends WrappedImageValue {
    style: ComponentType.croppedPhotoBlock;
}

export interface ComponentInfographics extends WrappedImageValue {
    style: ComponentType.infographics;
}

export interface ComponentDrop extends WrappedImageValue {
    style: ComponentType.drop;
}

export interface ComponentChessBlockSection extends WrappedImageValue {
    style: ComponentType.none;
    parentStyle: ParentStyle;
}

export interface ComponentWrappedImage extends AdditionalParentProps {
    type: ComponentType.wrappedImage;
    id: string;
    value:
        | ComponentDrop
        | ComponentInfographics
        | CroppedPhotoBlock
        | ComponentChessBlockSection;
}

export interface TabsContent {
    content: (ComponentRichText | ComponentTabs)[];
}

export interface RegularTab extends TabsContent {
    title: string;
}

export interface RegularTabs {
    type: ComponentType.regularTabs;
    value: {
        title: string;
        tabs: RegularTab[];
    };
    id: string;
}

export interface ImagedTab extends TabsContent {
    title: string;
    image: ImageValue;
}

export interface ImagedTabs {
    type: ComponentType.imagedTabs;
    value: {
        title: string;
        tabs: ImagedTab[];
    };
    id: string;
}

export interface ComponentTabs {
    type: ComponentType.tabs;
    value: (ImagedTabs | RegularTabs)[];
    id: string;
}

export interface ButtonCard {
    type: ComponentType.buttonCard;
    value: {
        title: string;
        action: {
            title: string;
            accentColor: string;
            action: {
                slug: string;
                url: string;
                title: string;
            };
        };
        illustration: ImageValue;
        description: string;
    };
    lastItem?: boolean;

    id: string;
}

export interface OrdinaryCard {
    type: ComponentType.ordinaryCard;
    value: {
        title: string;
        illustration?: ImageValue;
        description: string;
        action?: {
            title?: string;
            accentColor?: string;
            action?: {
                slug?: string;
                url?: string;
                title?: string;
            };
        };
    };
    lastItem?: boolean;

    id: string;
}

export interface TriangularArrows {
    style: ComponentType.triangularArrowsCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface BigCards {
    style: ComponentType.bigCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface MediumCards {
    style: ComponentType.mediumCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface SmallCards {
    style: ComponentType.smallCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface MarketingCards {
    style: ComponentType.marketingCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface CustomIllustrationCards {
    style: ComponentType.customIllustrationCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface WithoutBordersCards {
    style: ComponentType.withoutBordersCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface WavyArrowsCards {
    style: ComponentType.wavyArrowsCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface GridCards {
    style: ComponentType.gridCards;
    cards: (OrdinaryCard | ButtonCard)[];
}

export interface ComponentCards extends AdditionalParentProps {
    type: ComponentType.cards;
    value:
        | TriangularArrows
        | BigCards
        | MediumCards
        | SmallCards
        | WithoutBordersCards
        | WavyArrowsCards
        | GridCards
        | CustomIllustrationCards
        | MarketingCards;
    id: string;
}

export interface PlansCard {
    title: string;
    primaryText: string;
    secondaryText: string;
    description: string;
    features: string;
    action: {
        title: string;
        accentColor: ComponentColor.primary | ComponentColor.secondary;
        action: {
            slug: string;
            url: string;
            title: string;
        };
    };
}

export interface ComponentsPlans {
    type: ComponentType.plans;
    value: {
        values: PlansCard[];
    };
    id: string;
}

export interface DividedSection {
    type: ComponentType.dividedSection;
    value: {
        title: string;
        content: (ComponentAction | ComponentRichText)[];
    };
    oddAndLast: boolean;

    id: string;
}

export interface ComponentDividedBlock {
    type: ComponentType.dividedBlock;
    value: {
        title?: string;
        sections: DividedSection[];
    };
    id: string;
}

export const featuresLinks = [
    // talent urls
    'Matched to You',
    'Saved Vacancies',
    // 'Your Invitations',
    // 'Your applications',
    'Planned interviews',
    'Current contract',
    'Vacancies in process',
    // 'Expenses',

    // client urls
    // 'Your Professionals',
    // 'Saved Profiles',
    'Planned Interviews',
    'Transaction history',

    //general
    'Login',
    'Create vacancy',
    'Search vacancies',
    'Why GloPros',
    'Plans & prices',
    'Search professionals',
    'Customized search',
    'About us',
    'Create vacancy',
    'Login',
] as const;

type FeaturesLink = (typeof featuresLinks)[number];

export interface ItemMenu {
    type: ComponentType.menuItem;
    title: FeaturesLink;
    url: string;
    id: string;
    style: 'internal' | 'external';
}

export interface GroupTitle {
    type: ComponentType.groupTitle;
    title: string;
    id: string;
}

export interface Divider {
    type: ComponentType.divider;
}

export interface MenuItems {
    type: ComponentType.menuItems;
    title: string;
    items: (ItemMenu | GroupTitle | Divider)[];
    id: string;
}
export interface SearchMenuItem {
    type: ComponentType.searchMenuItem;
    title: string;
    description: string;
    icon: string;
    url: string;
    id: string;
}

export interface SearchMenuItems {
    type: ComponentType.searchMenuItems;
    placeholder: string;
    items: SearchMenuItem[];
    id: string;
    closeHandler?: Dispatch<SetStateAction<boolean>>;
}

export interface HeaderProfileButton {
    type: ComponentType.action;
    title: string;
    accentColor: string;
    style: 'external' | 'internal';
    action: {
        slug: string;
        url: string;
        title: string;
    };
    id: string;
}

export interface SearchInput {
    type: ComponentType.searchInput;
    placeholder: string;
    icon: string;
    argName: 'job_title' | 'location';
    name: string;
    id: string;
}

export interface SearchSelect {
    type: ComponentType.searchInputSelect;
    placeholder: string;
    values: [
        {
            id: 'professionals' | 'vacancies';
            name: string;
            icon: string | null;
        },
    ];
    icon: string;
    description: string | null;
    argName: string;
    id: string;
}

export interface ExtendedSearchMenuItem {
    type: ComponentType.extendedSearchMenuItem;
    placeholder: string;
    href: string;
    items: [];
    inputs: (SearchSelect | SearchInput)[];
    id: string;
}
interface SubTypeValue {
    title: string;
    value: string;
}

interface ProfileMenuItemToggle {
    type: ComponentType.profileMenuItem;
    subType: ComponentType.profileMenuToggle;
    subTypeValues: SubTypeValue[];
    title: string;
    icon: string | null;
    url: string;
    id: string;
}

interface ProfileMenuItemLink {
    type: ComponentType.profileMenuItem;
    subType: ComponentType.profileMenuLink;
    description: string | null;
    title: string;
    icon: string | null;
    url: string;
    id: string;
}

export interface ProfileMenuItems {
    type: ComponentType.profileMenuItems;
    icon: string;
    items: (
        | ProfileMenuItemToggle
        | ProfileMenuItemLink
        | HeaderProfileButton
    )[];
    id: string;
}

export interface NavigationBar {
    leftSide: (MenuItems | ItemMenu | ComponentInternalLink)[];
    rightSide: (
        | SearchMenuItems
        | ItemMenu
        | HeaderProfileButton
        | ExtendedSearchMenuItem
        | ProfileMenuItems
    )[];
}

export interface ComponentHeader {
    logo?: string;
    navigationBar?: NavigationBar;
    withBorder?: boolean;
}

export interface ComponentVideoBlock {
    type: ComponentType.videoBlock;
    value: {
        file?: { url: string };
        title?: string;
        description?: string;
        link?: string;
    };
    id: string;
}
