import { theme } from '@theme/theme';

import { IconProps } from './types';

const GlobalProfessionalsLogo: React.FC<IconProps> = ({
    width,
    height,
    isCursorPointer,
}) => {
    const style = { cursor: isCursorPointer ? 'pointer' : 'auto' };

    return (
        <svg
            style={style}
            width={width}
            height={height}
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 223.09 64.03">
            <g>
                <g>
                    <g>
                        <path
                            fill={theme.colors.primaryColor}
                            d="M63.23,6.08c-3.18,0-4.39,2.48-4.39,6.09s1.21,6.12,4.58,6.12c2.24,0,3.8-1.35,3.85-3.21h-3.67v-3.5h8.68v10.24h-3.48l-.49-2.45h-.05c-.92,1.75-2.72,2.88-5.55,2.88-5.47,0-8.73-4.26-8.73-10.08s3.26-10.05,9.32-10.05c5.28,0,8.22,3.13,8.68,6.82h-4.85c-.35-1.32-1.24-2.86-3.91-2.86Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M78.81,2.55v19.27h-4.31V2.55h4.31Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M87.7,22.25c-4.61,0-7.38-3.05-7.38-7.33s2.78-7.33,7.38-7.33,7.36,3.05,7.36,7.33-2.78,7.33-7.36,7.33ZM87.7,19.01c1.89,0,3.04-1.43,3.04-4.1s-1.16-4.1-3.04-4.1-3.07,1.43-3.07,4.1,1.16,4.1,3.07,4.1Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M110.91,14.92c0,4.53-2.34,7.33-6.01,7.33-1.99,0-3.45-1.08-3.99-1.81h-.05v1.37h-4.31V2.55h4.31v6.84h.05c.54-.73,1.99-1.81,3.99-1.81,3.66,0,6.01,2.8,6.01,7.33ZM106.59,14.92c0-2.69-1.08-4.1-2.83-4.1s-2.83,1.4-2.83,4.1,1.08,4.1,2.83,4.1,2.83-1.4,2.83-4.1Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M118.78,10.55c-1.56,0-2.32.59-2.45,1.91h-4.04c.13-2.64,2.02-4.88,6.52-4.88,3.88,0,6.04,1.51,6.04,5.85v6.33c0,.73.27,1.02.81,1.02v1.02c-.86.32-1.64.43-2.51.43-1.94,0-2.48-.92-2.48-1.89h-.05c-.89,1.02-2.51,1.89-4.34,1.89-2.91,0-4.53-1.64-4.53-3.96,0-2.75,2.02-4.01,5.25-4.66,1.78-.35,3.53-.62,3.53-1.78,0-.81-.57-1.29-1.75-1.29ZM120.53,16.78v-1.67c-.54.22-1.21.4-2.21.62-1.7.38-2.26,1.08-2.26,1.94,0,1.13.67,1.62,1.72,1.62,1.86,0,2.75-1.19,2.75-2.51Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M131.63,2.55v19.27h-4.31V2.55h4.31Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M54.74,27.55h9.35c4.01,0,6.52,2.1,6.52,6.14s-2.51,6.14-6.52,6.14h-4.5v6.98h-4.85v-19.27ZM59.59,35.88h3.48c1.67,0,2.7-.57,2.7-2.18s-1.02-2.18-2.7-2.18h-3.48v4.37Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M80.96,32.88v3.77h-.05c-2.21-.4-4.55.81-4.55,3.75v6.41h-4.31v-13.8h4.31v2.26h.05c.67-1.54,1.86-2.51,3.37-2.51.38,0,.7,0,1.19.11Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M88.86,47.25c-4.61,0-7.38-3.05-7.38-7.33s2.78-7.33,7.38-7.33,7.36,3.05,7.36,7.33-2.78,7.33-7.36,7.33ZM88.86,44.01c1.89,0,3.04-1.43,3.04-4.1s-1.16-4.1-3.04-4.1-3.07,1.43-3.07,4.1,1.16,4.1,3.07,4.1Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M103.04,32.43v.59h2.4v3.23h-2.4v10.56h-4.31v-10.56h-2.05v-3.23h2.05v-.94c0-3.1.89-4.77,4.04-4.77,1.21,0,1.89.11,2.67.27v3.23c-1.83,0-2.4,0-2.4,1.62Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M115.54,42.64h4.28c-.75,2.78-2.91,4.61-6.66,4.61-4.58,0-7.36-3.02-7.36-7.33s2.72-7.33,7.11-7.33c4.72,0,7.11,3.45,7.11,8.46h-9.92c0,1.94,1.43,2.96,2.96,2.96s2.18-.7,2.45-1.37ZM110.13,38.44h5.6c0-1.91-1.16-2.88-2.8-2.88s-2.8.97-2.8,2.88Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M133.68,42.75c0,2.91-2.21,4.5-6.04,4.5-4.93,0-6.82-2.21-6.95-5.01h4.04c.13,1.37,1.05,2.05,2.75,2.05,1.21,0,1.89-.4,1.89-1.19,0-1.19-1.29-1.35-3.48-1.83-2.34-.51-4.93-1.38-4.93-4.26,0-2.51,2.16-4.42,6.04-4.42,4.42,0,6.14,2.24,6.28,4.66h-4.04c-.13-1.1-.73-1.7-2.13-1.7-1.21,0-1.83.43-1.83,1.1,0,.97.86,1,2.99,1.46,2.72.59,5.42,1.51,5.42,4.63Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M147.4,42.75c0,2.91-2.21,4.5-6.04,4.5-4.93,0-6.82-2.21-6.95-5.01h4.04c.13,1.37,1.05,2.05,2.75,2.05,1.21,0,1.89-.4,1.89-1.19,0-1.19-1.29-1.35-3.48-1.83-2.34-.51-4.93-1.38-4.93-4.26,0-2.51,2.16-4.42,6.04-4.42,4.42,0,6.14,2.24,6.28,4.66h-4.04c-.13-1.1-.73-1.7-2.13-1.7-1.21,0-1.83.43-1.83,1.1,0,.97.86,1,2.99,1.46,2.72.59,5.42,1.51,5.42,4.63Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M148.83,29.46c0-1.24.94-1.91,2.16-1.91s2.16.67,2.16,1.91-.94,1.91-2.16,1.91-2.16-.65-2.16-1.91ZM153.14,33.02v13.8h-4.31v-13.8h4.31Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M162.01,47.25c-4.61,0-7.38-3.05-7.38-7.33s2.78-7.33,7.38-7.33,7.36,3.05,7.36,7.33-2.78,7.33-7.36,7.33ZM162.01,44.01c1.89,0,3.04-1.43,3.04-4.1s-1.16-4.1-3.04-4.1-3.07,1.43-3.07,4.1,1.16,4.1,3.07,4.1Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M184.22,37.49v9.32h-4.31v-7.95c0-2.08-.73-2.78-2.16-2.78-1.64,0-2.59.83-2.59,2.75v7.98h-4.31v-13.8h4.31v1.99h.05c.73-1.27,2.07-2.43,4.53-2.43,2.67,0,4.47,1.75,4.47,4.91Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M192.6,35.55c-1.56,0-2.32.59-2.45,1.91h-4.04c.13-2.64,2.02-4.88,6.52-4.88,3.88,0,6.04,1.51,6.04,5.85v6.33c0,.73.27,1.02.81,1.02v1.02c-.86.32-1.64.43-2.51.43-1.94,0-2.48-.92-2.48-1.89h-.05c-.89,1.02-2.51,1.89-4.34,1.89-2.91,0-4.53-1.64-4.53-3.96,0-2.75,2.02-4.01,5.25-4.66,1.78-.35,3.53-.62,3.53-1.78,0-.81-.57-1.29-1.75-1.29ZM194.35,41.78v-1.67c-.54.22-1.21.4-2.21.62-1.7.38-2.26,1.08-2.26,1.94,0,1.13.67,1.62,1.72,1.62,1.86,0,2.75-1.19,2.75-2.51Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M205.46,27.55v19.27h-4.31v-19.27h4.31Z"
                        />
                        <path
                            fill={theme.colors.secondaryColor_basic}
                            d="M219.74,42.75c0,2.91-2.21,4.5-6.04,4.5-4.93,0-6.82-2.21-6.95-5.01h4.04c.13,1.37,1.05,2.05,2.75,2.05,1.21,0,1.89-.4,1.89-1.19,0-1.19-1.29-1.35-3.48-1.83-2.34-.51-4.93-1.38-4.93-4.26,0-2.51,2.16-4.42,6.04-4.42,4.42,0,6.14,2.24,6.28,4.66h-4.04c-.13-1.1-.73-1.7-2.13-1.7-1.21,0-1.83.43-1.83,1.1,0,.97.86,1,2.99,1.46,2.72.59,5.42,1.51,5.42,4.63Z"
                        />
                    </g>
                    <g>
                        <path
                            fill={theme.colors.primaryColor}
                            d="M59.13,56.71c-.15.28-.39.5-.71.67-.32.17-.72.26-1.19.26h-.88v2.08h-1.41v-5.8h2.29c.46,0,.85.08,1.17.24.32.16.56.38.72.66.16.28.24.6.24.97,0,.34-.08.64-.23.92ZM57.72,56.32c.13-.13.2-.31.2-.54s-.07-.41-.2-.54c-.13-.13-.33-.19-.6-.19h-.77v1.45h.77c.27,0,.47-.06.6-.19Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M60.97,59.5c-.36-.19-.65-.47-.85-.83s-.31-.78-.31-1.26.1-.89.31-1.25c.21-.36.5-.64.86-.83s.77-.29,1.22-.29.86.1,1.22.29.65.47.86.83c.21.36.31.78.31,1.25s-.11.89-.32,1.25c-.21.36-.5.64-.87.83-.37.19-.78.29-1.23.29s-.86-.1-1.22-.29ZM62.88,58.27c.19-.2.29-.48.29-.85s-.09-.65-.28-.85c-.19-.2-.41-.3-.68-.3s-.5.1-.69.29c-.18.2-.27.48-.27.86s.09.65.27.85.4.3.67.3.5-.1.69-.3Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M71.98,55.11l-1.25,4.61h-1.56l-.73-2.99-.75,2.99h-1.55l-1.26-4.61h1.41l.65,3.3.78-3.3h1.5l.79,3.28.64-3.28h1.33Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M76.84,57.75h-3.2c.02.29.11.51.28.66.16.15.36.23.6.23.35,0,.6-.15.74-.45h1.5c-.08.3-.22.58-.42.82s-.45.43-.76.57-.64.21-1.02.21c-.45,0-.85-.1-1.21-.29-.35-.19-.63-.47-.83-.83-.2-.36-.3-.78-.3-1.26s.1-.9.29-1.26c.2-.36.47-.63.82-.83.35-.19.76-.29,1.21-.29s.84.09,1.19.28c.35.19.62.46.81.8.2.35.29.75.29,1.21,0,.13,0,.27-.02.41ZM75.42,56.97c0-.24-.08-.44-.25-.58-.17-.14-.37-.21-.62-.21s-.44.07-.6.21-.26.33-.3.59h1.77Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M79.64,55.28c.25-.15.52-.22.83-.22v1.5h-.39c-.35,0-.62.08-.79.23-.18.15-.26.42-.26.8v2.14h-1.41v-4.61h1.41v.77c.17-.25.37-.45.62-.6Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M85.47,57.75h-3.2c.02.29.11.51.28.66.16.15.36.23.6.23.35,0,.6-.15.74-.45h1.5c-.08.3-.22.58-.42.82s-.45.43-.76.57-.64.21-1.02.21c-.45,0-.85-.1-1.21-.29-.35-.19-.63-.47-.83-.83-.2-.36-.3-.78-.3-1.26s.1-.9.29-1.26c.2-.36.47-.63.82-.83.35-.19.76-.29,1.21-.29s.84.09,1.19.28c.35.19.62.46.81.8.2.35.29.75.29,1.21,0,.13,0,.27-.02.41ZM84.05,56.97c0-.24-.08-.44-.25-.58-.17-.14-.37-.21-.62-.21s-.44.07-.6.21-.26.33-.3.59h1.77Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M86.23,56.16c.18-.36.42-.63.73-.83.31-.19.65-.29,1.03-.29.3,0,.58.06.83.19.25.13.45.3.59.51v-2.14h1.41v6.12h-1.41v-.66c-.13.22-.32.4-.57.53-.25.13-.53.2-.86.2-.38,0-.72-.1-1.03-.29-.31-.2-.55-.47-.73-.83-.18-.36-.27-.78-.27-1.25s.09-.89.27-1.25ZM89.12,56.58c-.2-.2-.43-.31-.71-.31s-.52.1-.72.3-.29.48-.29.83.1.63.29.84.43.31.72.31.52-.1.71-.31.29-.48.29-.83-.1-.63-.29-.83Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M95.59,55.24c.25-.13.53-.2.85-.2.38,0,.72.1,1.03.29s.55.47.73.83c.18.36.27.77.27,1.25s-.09.89-.27,1.25c-.18.36-.42.64-.73.83-.31.2-.65.29-1.03.29-.33,0-.61-.07-.85-.19-.24-.13-.43-.3-.57-.52v.64h-1.41v-6.12h1.41v2.16c.13-.21.32-.39.57-.52ZM96.74,56.58c-.2-.2-.44-.3-.72-.3s-.52.1-.71.31c-.2.2-.29.48-.29.83s.1.63.29.83c.2.2.43.31.71.31s.52-.1.72-.31c.2-.21.3-.49.3-.84s-.1-.63-.29-.83Z"
                        />
                        <path
                            fill={theme.colors.primaryColor}
                            d="M103.93,55.11l-2.89,6.8h-1.52l1.06-2.35-1.88-4.46h1.58l1.07,2.89,1.06-2.89h1.53Z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        fill={theme.colors.primaryColor}
                        d="M118.59,55.15c-1.3-.42-2.76.2-3.22,1.38-.51,1.29.04,2.58,1.34,3.15.1.04.22.04.23.18h1.39c.08-.15.24-.12.35-.16.75-.3,1.27-.81,1.48-1.57.37-1.28-.32-2.56-1.58-2.97ZM117.71,58.63c-.6,0-1.11-.52-1.13-1.16-.02-.63.5-1.21,1.11-1.22.6,0,1.15.55,1.16,1.18.02.63-.54,1.21-1.14,1.21Z"
                    />
                    <path
                        fill={theme.colors.primaryColor}
                        d="M132.38,55.04c-1.3-.15-2.5.55-2.84,1.68-.35,1.18.21,2.41,1.34,2.93.13.05.32.03.38.21h1.39c.03-.13.16-.12.25-.15,1.05-.4,1.63-1.25,1.61-2.36-.02-1.16-.95-2.17-2.13-2.31ZM131.94,58.63c-.59-.02-1.1-.58-1.11-1.19,0-.63.55-1.2,1.15-1.19.61,0,1.13.56,1.11,1.21,0,.65-.54,1.19-1.15,1.17Z"
                    />
                    <path
                        fill={theme.colors.primaryColor}
                        d="M137.76,56.98c-.28-.08-.57-.13-.85-.21-.23-.05-.56-.06-.54-.38.03-.32.36-.28.6-.28.22,0,.42.05.64.07.37.04.67.28,1.05.36,0-.28-.02-.55,0-.8.04-.32-.11-.45-.4-.54-.58-.18-1.18-.24-1.78-.18-.88.09-1.5.65-1.55,1.36-.06.78.33,1.22,1.31,1.47.32.08.65.16.98.24.15.04.25.12.26.28,0,.18-.1.28-.27.32-.27.08-.55.04-.82,0-.44-.05-.82-.3-1.26-.42-.01.64-.25,1.34.72,1.48,0,0,.02.05.02.08h1.67c.22-.11.45-.22.68-.33.37-.18.51-.53.71-.85v-.73c-.21-.54-.64-.81-1.17-.97Z"
                    />
                    <path
                        fill={theme.colors.primaryColor}
                        d="M129.24,55.45c.04-.3-.12-.35-.38-.34-.49.01-.85.26-1.28.65.15-.64-.19-.66-.58-.62-.14.01-.28.01-.42,0-.25-.03-.35.05-.35.32.02,1.47.02,2.94.02,4.4h1.2c.12-.17.14-.35.14-.55,0-.58,0-1.15,0-1.73,0-.75.27-1.14,1.02-1.13.65,0,.67-.28.62-.73-.01-.09-.01-.18,0-.27Z"
                    />
                    <path
                        fill={theme.colors.primaryColor}
                        d="M112.37,52.58c-.55.03-1.11,0-1.66,0h-1.29c-2.48,0-4.18,2.09-3.64,4.47.4,1.79,2.23,2.97,4.06,2.6,1.76-.35,3.05-2.23,2.74-3.97-.04-.22-.12-.3-.35-.3-.65.01-1.29,0-1.94,0-.78,0-1.22.35-1.43,1.08-.07.26-.03.36.26.35.54-.01,1.08,0,1.62,0,.31,0,.38.07.24.36-.34.71-1.24,1.22-2.05,1.13-.88-.1-1.61-.74-1.78-1.55-.27-1.33.7-2.53,2.1-2.56.6-.02,1.2.01,1.8,0,.84-.02,1.46-.51,1.62-1.23.05-.25.03-.39-.3-.37Z"
                    />
                    <path
                        fill={theme.colors.primaryColor}
                        d="M124.2,53.11c-.66-.16-1.35-.16-1.91-.15-.41,0-.7.02-.99,0-.39-.04-.52.07-.51.48.03,1.74.02,3.48.02,5.23,0,1.14,0,1.12,1.15,1.11.31,0,.35-.12.35-.38-.02-.52.02-1.06-.02-1.58-.02-.37.06-.52.47-.48.55.03,1.11-.04,1.64-.21.54-.17,1.02-.44,1.27-.98.62-1.32-.03-2.68-1.46-3.04ZM123.91,55.94c-.17.06-.35.09-.54.12q-1.1.17-1.08-.89c0-.09,0-.18,0-.25,0-.06,0-.12,0-.16.02-.31.07-.44.27-.48.03,0,.06-.02.1-.02.08,0,.21,0,.35,0,.08,0,.18.02.29.02.82.07,1.11.34,1.06,1-.01.32-.17.55-.47.65Z"
                    />
                    <path
                        fill={theme.colors.primaryColor}
                        d="M113.62,52.58c-.3,0-.39.09-.38.38.02,1.07,0,2.15,0,3.22v2.63c0,.96,0,.94.99.96.37,0,.46-.11.46-.46-.02-1.9,0-3.81,0-5.72,0-1.03,0-1.02-1.07-1.02Z"
                    />
                </g>
                <path
                    fill={theme.colors.primaryColor}
                    d="M41.71,22.13h-3.64c-1.51-5.11-6.25-8.85-11.84-8.85-6.82,0-12.36,5.54-12.36,12.35,0,.02,0,.04,0,.07v9.89s0,0,0,0c-2.2-2.72-3.52-6.19-3.52-9.95,0-8.76,7.12-15.88,15.88-15.88v.02h16.48s-.02-.02-.03-.03c3.59-.3,6.42-3.3,6.42-6.97h-21.86c-.34-.01-.67-.02-1.01-.02C13.61,2.75,3.35,13.01,3.35,25.63c0,8.06,4.19,15.17,10.52,19.24,0,0,0,0,0,0v11.85h7v-8.85c1.72.42,3.51.64,5.36.64,11.42,0,20.91-8.42,22.61-19.38.17-1.14.26-2.31.26-3.5s-.09-2.35-.28-3.5h-7.11ZM26.23,20.28c1.61,0,3.06.72,4.04,1.85.82.94,1.31,2.17,1.31,3.5s-.49,2.56-1.31,3.5c-.98,1.13-2.43,1.85-4.04,1.85-2.95,0-5.34-2.39-5.36-5.33v-.03h0c0-2.95,2.4-5.35,5.36-5.35ZM26.23,41.51c-1.88,0-3.68-.33-5.36-.94v-3.81c1.62.78,3.44,1.22,5.36,1.22,5.59,0,10.33-3.74,11.84-8.85h3.64c-1.6,7.08-7.93,12.38-15.48,12.38Z"
                />
            </g>
        </svg>
    );
};

export default GlobalProfessionalsLogo;
