import { CSSProperties, JSX, MouseEventHandler } from 'react';

import Loader from 'components/Loader';

import { StyledButton } from './ColoredButton.styled';

interface Props {
    isLoading?: boolean;
    title: string;
    handler?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    padding?: string;
    style?: CSSProperties | undefined;
    color?: 'yellow' | 'blue' | 'transparent';
    loaderSize?: number;
    maxHeight?: string;
    Icon?: JSX.Element;
    RightIcon?: JSX.Element;
}

const ColoredButton: React.FC<Props> = ({
    title,
    isLoading = false,
    handler = () => null,
    color = 'blue',
    disabled = false,
    padding = '16px 20px',
    style = undefined,
    maxHeight = '48px',
    loaderSize = 20,
    Icon,
    RightIcon,
}) => {
    return (
        <StyledButton
            style={style}
            disabled={isLoading || disabled}
            onClick={handler}
            $color={color}
            $padding={padding}
            $maxHeight={maxHeight}>
            {isLoading ? (
                <Loader size={loaderSize} height="auto" color="inherit" />
            ) : (
                <>
                    {Icon} {title} {RightIcon}
                </>
            )}
        </StyledButton>
    );
};

export default ColoredButton;
