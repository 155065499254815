import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';

interface StyledButtonProps extends ButtonProps {
    $opened: boolean;
}

export const ArrowUp = styled(KeyboardArrowUpIcon)(() => ({
    width: '18px',
    height: '15px',
    padding: '0px',
}));

export const ArrowDown = styled(KeyboardArrowDownIcon)(() => ({
    width: '18px',
    height: '15px',
    padding: '0px',
}));

export const StyledButton = styled(
    Button,
    transientOptions
)<StyledButtonProps>(({ $opened, theme }) =>
    mq({
        ...theme.fonts.body_2,

        color: $opened ? theme.colors.primaryColor : theme.colors.primaryText,
        textTransform: 'none',
        margin: ['0 8px', '0 8px', '0 8px', '0 12px'],
        padding: '0px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    })
);

export const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        minWidth: 180,
        color: theme.colors.primaryText,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,

        '& .MuiMenu-list': {
            padding: '0',
        },
        '& .MuiMenuItem-root': {
            '&:hover': {
                backgroundColor: theme.colors.dropDownHover,
                color: theme.colors.primaryColor,
            },
        },
    },
}));
