import { styled } from '@mui/material';

const ModalWindowWrapper = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    background: 'white',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    maxWidth: '620px',
    outline: 'none',
});

export default ModalWindowWrapper;
