import { getLocationSuggestions } from 'pages/api/getLocationAutocomplete';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useSWR from 'swr';

import useDebounce from 'hooks/useDebounce';
import { useFormValues } from 'hooks/useFormValues';
import { theme } from 'theme/theme';

import HomeIcon from 'components/SVGIcons/HomeIcon';

import AutocompleteInput from '../AutocompleteInput';
import { InputsStateProps } from '../ExtendedSearchMenu';
import { AutocompleteWindow } from '../JobTitleInput/JobTitleInput.styled';
import { LiContentWrapper, StyledLi, StyledUl } from './LocationInput.styled';

interface Props {
    title: string;
    placeholder: string;
    inputFormKey: 'location' | 'job_title';
    searchHandler: () => void;
}

const autocompleteValues = [
    { id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI', img: '', value: 'London, UK' },
    {
        id: 'ChIJVXealLU_xkcRja_At0z9AGY',
        img: '',
        value: 'Amsterdam, Netherlands',
    },
    { id: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ', img: '', value: 'Berlin, Germany' },
    { id: 'ChIJZ2jHc-2kw0cRpwJzeGY6i8E', img: '', value: 'Brussels, Belgium' },
    { id: 'ChIJi3lwCZyTC0cRkEAWZg-vAAQ', img: '', value: 'Prague, Czechia' },
];

const LocationInput: React.FC<Props> = ({
    title,
    placeholder,
    inputFormKey,
    searchHandler,
}) => {
    const [isOnFocus, setOnFocus] = useState(false);
    const { setValue } = useFormContext<InputsStateProps>();
    const themeColors = theme.colors;

    const formValues = useFormValues();
    const locationValue = formValues.location;
    const debouncedLocationValue = useDebounce(locationValue, 300);

    const autocompleteHandler = (value: string, id: string) => {
        setValue(inputFormKey, value);
        setValue('mainLocationId', id);
        setOnFocus(false);
    };

    const locationsFetcher = async (value: string) => {
        const { data } = await getLocationSuggestions(value);

        return data;
    };

    const { data: locationSuggestions } = useSWR(
        debouncedLocationValue.length >= 2 ? locationValue : null,
        locationsFetcher
    );

    return (
        <AutocompleteInput
            title={title}
            placeholder={placeholder}
            inputFormKey={inputFormKey}
            isOnFocus={isOnFocus}
            setOnFocus={setOnFocus}
            searchHandler={searchHandler}>
            {locationSuggestions?.status === 'OK' && (
                <AutocompleteWindow>
                    <StyledUl>
                        {locationSuggestions.predictions.map((prediction) => {
                            return (
                                <StyledLi
                                    className="liPopper"
                                    key={prediction.place_id}
                                    onClick={() =>
                                        autocompleteHandler(
                                            prediction.description,
                                            prediction.place_id
                                        )
                                    }>
                                    {prediction.description}
                                </StyledLi>
                            );
                        })}
                    </StyledUl>
                </AutocompleteWindow>
            )}

            {debouncedLocationValue.length < 2 && (
                <AutocompleteWindow>
                    <StyledUl>
                        {autocompleteValues.map((place) => {
                            return (
                                <StyledLi
                                    key={place.id}
                                    className="liPopper"
                                    onClick={() =>
                                        autocompleteHandler(
                                            place.value,
                                            place.id
                                        )
                                    }>
                                    <LiContentWrapper className="liPopper">
                                        {place.img === 'HomeIcon' && (
                                            <HomeIcon
                                                width={12}
                                                height={12}
                                                color={themeColors.primaryColor}
                                            />
                                        )}
                                        {place.value}
                                    </LiContentWrapper>
                                </StyledLi>
                            );
                        })}
                    </StyledUl>
                </AutocompleteWindow>
            )}
        </AutocompleteInput>
    );
};

export default LocationInput;
