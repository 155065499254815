import { styled } from '@mui/material';

export const Wrapper = styled('div')({
    display: 'flex',
    gap: '35px',
});

export const PhotoContainer = styled('div')(({ theme: { colors, fonts } }) => ({
    ...fonts.body_1,
    color: colors.secondaryColor_2,
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
    padding: '15px 0px',
    width: '175px',
    height: '130px',
    borderRadius: '10px',
    border: `1px solid ${colors.dividerColor}`,
    background: colors.secondaryColor_3,
    cursor: 'pointer',
    flexShrink: '0',
    position: 'relative',
}));

export const InfoSection = styled('div')(({ theme: { colors, fonts } }) => ({
    color: colors.primaryText,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    h2: {
        ...fonts.h6_headline,
    },

    span: {
        ...fonts.body_2,
    },
}));
