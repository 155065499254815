import { styled } from '@mui/material';

import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

export const Content = styled('div')({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 160px))',
    gap: '80px',
    padding: '50px 90px 100px',
    justifyContent: 'center',
    borderTop: '1px solid rgba(206, 214, 225, 0.5)',
    borderBottom: '1px solid rgba(206, 214, 225, 0.5)',
    placeContent: 'center',

    [mobileStyles]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '19px 16px',
        gap: '0px',
        border: 'none',
    },
});

export const Background = styled('div')({
    width: '100%',
    background: 'linear-gradient(180deg, #012B5B 0%, #03172F 100%)',
    padding: '60px 175px 80px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',

    [mobileStyles]: {
        padding: '0px',
        paddingBottom: '150px',
    },

    [tabletStyles]: {
        padding: '60px 0px',
    },
});

export const AllRightsText = styled('p')(({ theme: { fonts } }) => ({
    ...fonts.body_2,
    display: 'flex',
    color: 'white',

    [mobileStyles]: {
        paddingLeft: '16px',
    },
}));
