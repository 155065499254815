import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { SERVER_ERROR } from 'constants/toast-messages';

import { toastError } from 'components/CustomToast/customToastFunctions';

interface Props {
    error: unknown;
    altMessage?: string;
}

const axiosServerError = ({ error, altMessage = SERVER_ERROR }: Props) => {
    if (error instanceof AxiosError) {
        const responseData = error?.response?.data;

        const serverError =
            typeof responseData === 'string' ? responseData : responseData[0];
        toast.error(serverError || altMessage);
        return;
    }

    toastError(altMessage);
    return;
};

export default axiosServerError;
