import { CSSProperties } from 'react';
import { useFormContext } from 'react-hook-form';

import { theme } from 'theme/theme';

import CrossInCircleIcon from 'components/SVGIcons/CrossInCircleIcon';

import { IconWrapper, SelectedTabWrapper } from './SelectedTab.styled';

const { white, primaryColor } = theme.colors;

interface SelectedTabProps {
    value?: string;
    inputFormKey: string;
    secondaryStyle?: boolean;
    title: string;
    onDelete?: () => void;
    sx?: CSSProperties;
    children?: React.ReactNode;
}

const SelectedTab: React.FC<SelectedTabProps> = ({
    inputFormKey,
    title,
    value = title,
    secondaryStyle = false,
    onDelete,
    children,
    sx,
}) => {
    const { getValues, setValue, trigger } = useFormContext();

    const deleteHandler = () => {
        const inputValues = getValues(inputFormKey) as string[];

        const filteredValues = inputValues.filter((stored) => stored !== value);

        setValue(inputFormKey, [...filteredValues], { shouldDirty: true });
        trigger(inputFormKey);

        onDelete?.();
    };

    return (
        <SelectedTabWrapper
            sx={sx}
            $secondaryStyle={secondaryStyle}
            onMouseDown={(e) => e.stopPropagation()}>
            {children}

            {title}
            <IconWrapper
                data-testid="delete-tab-button"
                onClick={deleteHandler}>
                <CrossInCircleIcon
                    width={13}
                    height={14}
                    color={secondaryStyle ? primaryColor : white}
                />
            </IconWrapper>
        </SelectedTabWrapper>
    );
};

export default SelectedTab;
