import axiosInstance from '../axiosConfig';

export type CompanyRole =
    | 'company_administrator'
    | 'company_creator'
    | 'company_owner'
    | 'company_member'
    | 'company_team_creator';

export interface Photo {
    title: string;
    height: number;
    width: number;
    url: string;
}

export interface OrganizationMember {
    user_id: number;
    id: number;
    photo: Photo;
    first_name: string;
    last_name: string;
    roles: CompanyRole[];
    email: string;
    member_of: string;
    location: Record<string, unknown>;
    phone_number: string;
    job: string;
    is_invitation_pending: boolean;
    is_archived: boolean;
    is_deleted: boolean;
    recruiter_id: number;
}

export interface Response {
    count: number;
    next: null;
    previous: null;
    results: OrganizationMember[];
}

interface Params {
    limit: number;
    offset?: number;
    exclude_invitation_pending?: boolean;
    search?: string;
}

const getOrganizationMembers = (slug: string, params?: Params) => {
    return axiosInstance.get<Response>(`/wt/api/v1/company/${slug}/members/`, {
        params,
    });
};

export default getOrganizationMembers;
