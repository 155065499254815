import { useDisplayType } from 'hooks/useDisplayType';
import { useUserData } from 'state/userData';

import { ConstructorProps } from '../Components/Constructor/Constructor';

const helpAndSupportStaticContent: ConstructorProps[] = [
    {
        type: 'link',
        title: 'Contact us',
        href: '/contact-us',
    },
    {
        type: 'link',
        title: 'FAQ page',
        href: '/faq',
    },
    {
        type: 'link',
        title: 'Terms of service',
        href: '/terms-of-service',
    },

    {
        type: 'link',
        title: 'Privacy policy',
        href: '/privacy-policy',
    },
];

export const useGloProsFooterSchema = () => {
    const { isMobile } = useDisplayType();
    const { isLoggedIn, user } = useUserData();

    const schema: ConstructorProps[] = [
        {
            type: 'verticalSection',
            title: 'Contact',
            content: [
                {
                    type: 'text',
                    title: 'Singel 126',
                },
                {
                    type: 'text',
                    title: '1015 AE Amsterdam',
                },
                {
                    type: 'text',
                    title: 'The Netherlands',
                },
                {
                    type: 'email',
                    title: 'info@glopros.com',
                    href: 'mailto:info@glopros.com',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'GloPros',
            content: [
                {
                    type: 'link',
                    title: 'Talk to our experts',
                    href: '/talk-to-our-experts',
                },
                {
                    type: 'link',
                    title: 'Careers at GloPros',
                    href: '/careers-at-glopros',
                },
                {
                    type: 'link',
                    title: 'About us',
                    href: '/about-us',
                },

                {
                    type: 'link',
                    title: 'GloPros news',
                    href: '/glopros-news',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'Help & Support',
            isVisible: !isLoggedIn,
            content: helpAndSupportStaticContent,
        },

        {
            type: 'verticalSection',
            title: 'Help & Support',
            isVisible: isLoggedIn && user.role === 'client',
            content: [
                ...helpAndSupportStaticContent,
                {
                    type: 'link',
                    title: 'Code of conduct',
                    href: '/code-of-conduct-for-companies/',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'Help & Support',
            isVisible: isLoggedIn && user.role === 'professional',
            content: [
                ...helpAndSupportStaticContent,
                {
                    type: 'link',
                    title: 'Code of conduct',
                    href: '/code-of-conduct-for-professionals/',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'My Company',
            isVisible: isLoggedIn && user.role === 'client',
            content: [
                {
                    type: 'link',
                    title: 'Account settings',
                    href: '/profile/settings/',
                },
                {
                    type: 'link',
                    title: 'My reports',
                    href: '/contracts',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'My Profile',
            isVisible: isLoggedIn && user.role === 'professional',
            content: [
                {
                    type: 'link',
                    title: 'Manage my profile',
                    href: '/tp/${slug}',
                },
                {
                    type: 'link',
                    title: 'Account settings',
                    href: '/profile/settings/',
                },
                {
                    type: 'link',
                    title: 'My reports',
                    href: '/contracts',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'My Profile',
            isVisible: isLoggedIn && user.role === 'partner',
            content: [
                {
                    type: 'link',
                    title: 'Account settings',
                    href: '/profile/settings/',
                },
                {
                    type: 'link',
                    title: 'My reports',
                    href: '/contracts',
                },
            ],
        },

        {
            type: isMobile ? 'mobileContent' : 'verticalSection',
            title: 'Trust Center',
            content: [
                {
                    type: 'isoContent',
                    content: [
                        {
                            title: 'GloPros ISO 27001',
                            href: 'https://glopros.eu.trust.site/',
                            icon: '/img/ISO.png',
                        },
                    ],
                },
            ],
        },

        {
            type: isMobile ? 'mobileContent' : 'verticalSection',
            title: 'Social',

            content: [
                {
                    type: 'socialIcons',
                    content: [
                        {
                            href: `https://www.linkedin.com/company/glopros/`,
                            icon: '/img/linkedin_icon.svg',
                        },
                        {
                            href: `https://www.youtube.com/@GloPros`,
                            icon: '/img/youtube_icon.svg',
                        },

                        // {
                        //     href: '#',
                        //     icon: '/img/twitter_icon.svg',
                        // },
                        // {
                        //     href: '#',
                        //     icon: '/img/gitLab_icon.svg',
                        // },
                    ],
                },
            ],
        },
    ];

    return schema;
};
