import { Box, FormControl, styled } from '@mui/material';

export const StyledBox = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
}));

export const StyledFormControl = styled(FormControl)({
    width: '100%',

    '& .MuiInputBase-input': {
        padding: '12px 17px',
        minHeight: '3rem',
        boxSizing: 'border-box',
    },

    '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
    },
});

export const BottomButtons = styled('div')(({ theme: { colors } }) => ({
    padding: '12px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    background: colors.secondaryColor_4,
    flexWrap: 'wrap',
    gap: '40px',
}));

export const RWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '30px',
    alignSelf: 'end',
});

export const SelectWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '300px',
    overflowY: 'auto',
});

export const StyledButton = styled('button')(
    ({ theme: { colors, fonts }, disabled }) => ({
        ...fonts.body_2,
        color: disabled ? colors.secondaryText : colors.primaryColor,
        cursor: disabled ? 'not-allowed' : 'pointer',
        padding: '0px',
        border: '0px',
        background: 'none',
    })
);

export const Placeholder = styled('em')(({ theme: { colors, fonts } }) => ({
    ...fonts.body_2,
    color: colors.placeholderColor,
    fontWeight: 400,
}));
